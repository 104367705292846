export const CONTEXT = {
  TRIGGERS: 'triggers',
};

export const TEMPORARY_STORE_TO_WATCH = {
  [CONTEXT.TRIGGERS]: {
    temporarySettingsField: 'temporaryTriggers',
    temporaryIDField: 'currentTriggersIndex',
  },
};

export const STORE_TO_WATCH = {
  [CONTEXT.TRIGGERS]: 'triggers',
};

export const CONTEXT_TO_CHECK_FOR_CONFLICT = {
  [CONTEXT.TRIGGERS]: {
    storeToWatch: [STORE_TO_WATCH[CONTEXT.TRIGGERS]],
    contextToWatch: [CONTEXT.TRIGGERS],
  },
};

export const FILTER_DIMENSIONS = {
  DATE_RANGE: 'dateRange',
  THEMATICS: 'thematics',
  NORMAL: 'normal',
};
