import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import { grayShades } from 'styles/abstracts/colors';
import DatePickerContainer from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/DatePickerContainer';
import CriteriaKeysFilter from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/CriteriaKeysFilter';
import ThematicsFilter from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/ThematicsFilter';
import { setRangeFilter } from '_Resources/Benchmark/Actions/Filters/setRangeFilter';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { FILTER_TYPES } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/filtersConfig';
import { addFilters } from '_Resources/Benchmark/Actions/Filters/addFilters';
import { removeFilters } from '_Resources/Benchmark/Actions/Filters/removeFilters';
import { isValidFullDateRange } from '../../../../BenchmarkGroupCard/_Utils/checkDateRange';

const SwitcherContainer = styled(Col)`
  padding: 1rem;
  border-bottom: 1px solid ${grayShades.g400};
`;

const CustomCol = styled(Col)`
  margin: 1em;
`;

class FiltersSwitcher extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    /* eslint-disable react/forbid-prop-types */
    thematicsFilters: PropTypes.any.isRequired,
    normalFilters: PropTypes.any.isRequired,
    projectPeriods: PropTypes.any.isRequired,
    /* eslint-enable react/forbid-prop-types */
    context: PropTypes.string.isRequired,
    onRemovedNormalFilter: PropTypes.func.isRequired,
    onSelectedNormalFilter: PropTypes.func.isRequired,
    dispatchSetRangeFilter: PropTypes.func.isRequired,
    onRemovedThematicFilter: PropTypes.func.isRequired,
    onSelectedThematicFilter: PropTypes.func.isRequired,
    globalDateRangesettings: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  getRangeDate() {
    const { globalDateRangesettings } = this.props;
    const specificDateRangeSettings =
      get(this.props, ['currentSettingsFilters', 'dateRange']) || {};
    if (
      !specificDateRangeSettings.startDate ||
      !specificDateRangeSettings.endDate
    ) {
      this.props.dispatchSetRangeFilter(
        this.props.context,
        globalDateRangesettings,
        globalDateRangesettings,
      );
      return globalDateRangesettings;
    }

    return { ...specificDateRangeSettings };
  }

  dispatchRangeFilters(type, values) {
    const { globalDateRangesettings } = this.props;
    const finalValidFullDateRange = isValidFullDateRange(values, globalDateRangesettings);
    this.props.dispatchSetRangeFilter(type, values, finalValidFullDateRange);
  }

  content = {
    'date-range': () => (
      <DatePickerContainer
        dispatcher={(type, values) => this.dispatchRangeFilters(type, values)}
        projectPeriods={this.props.projectPeriods}
        dateRange={this.getRangeDate()}
        context={this.props.context}
        title="Period"
      />
    ),
    thematics: () => (
      <ThematicsFilter
        context={this.props.context}
        onRemoved={this.props.onRemovedThematicFilter}
        onSelected={this.props.onSelectedThematicFilter}
        thematics={this.props.thematicsFilters}
        initialValues={get(this.props, ['currentSettingsFilters', 'filters', 'thematic']) || null}
      />
    ),
    filters: () => (
      <CriteriaKeysFilter
        context={this.props.context}
        onRemoved={this.props.onRemovedNormalFilter}
        onSelected={this.props.onSelectedNormalFilter}
        normalFilters={this.props.normalFilters}
        initialValues={get(this.props, ['currentSettingsFilters', 'filters', 'normal']) || null}
      />
    ),
  };

  state = {
    menuOption: 'date-range',
  };

  getSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'date-range',
        label: t('Date range'),
      },
      {
        id: 'thematics',
        label: t('Thematics'),
      },
      {
        id: 'filters',
        label: t('Filters'),
      },
    ];
  }

  onMenuOptionSelected = menuOption => {
    this.setState({ menuOption });
  };

  render() {
    const { menuOption } = this.state;
    const content = this.content[menuOption];
    return (
      <>
        <Row>
          <SwitcherContainer>
            <QSwitcher
              selected={menuOption}
              options={this.getSwitcherOptions()}
              onOptionSelected={this.onMenuOptionSelected}
            />
          </SwitcherContainer>
        </Row>
        <Row>
          <CustomCol>{content()}</CustomCol>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({
  periods: { projectPeriods, currentPeriod },
  benchmark: { activeBenchmark },
  filters: { projectFilters },
}) => ({
  projectPeriods,
  currentPeriod,
  globalDateRangesettings: get(activeBenchmark, ['settings', 'globalSettings', 'dateRange']),
  normalFilters: get(projectFilters, ['criteriaKeys', 'normal']),
  thematicsFilters: get(projectFilters, ['thematics']),
});

const mapDispatchToProps = (dispatch, { context }) => ({
  dispatchSetRangeFilter: (type, values, mergedValues) => {
    dispatch(setRangeFilter(type, values, mergedValues));
  },
  onSelectedNormalFilter: filterValues => dispatch(addFilters(context, FILTER_TYPES.NORMAL, filterValues)),
  onRemovedNormalFilter: filterValues => dispatch(removeFilters(context, FILTER_TYPES.NORMAL, [filterValues, ''])),
  onSelectedThematicFilter: filterValues => dispatch(addFilters(context, FILTER_TYPES.THEMATIC, filterValues)),
  onRemovedThematicFilter: (...args) => dispatch(removeFilters(context, FILTER_TYPES.THEMATIC, args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('reporting')(FiltersSwitcher));
