import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { get, uniq } from 'lodash';
import MutationSaveTriggersTable from '_Resources/Triggers/Services/MutationSaveTriggersTable';
import { spacing } from 'styles/abstracts/variables';
import { grayShades } from 'styles/abstracts/colors';
import { fontSizes } from 'styles/abstracts/typography';
import QInput from '_Components/Fields/QInput/QInput';
import QModalBody from '_Components/Modals/QModalBody/QModalBody';
import QTitle from '_Components/Texts/QTitle/QTitle';
import QButton from '_Components/Buttons/QButton/QButton';
import QModal from '_Components/Modals/QModal/QModal';
import QSelect from '_Components/Fields/QSelect/QSelect';
import ModalFiltersPage from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/ModalFiltersPage';
import { columnCollapseContent } from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/configs/CollapseContents';
import { CONTEXT } from 'Routes/Insights/Triggers/_Components/configs/globalConfig';
import { StyledQButton } from 'Routes/Insights/Triggers/_Components/Modals/_Components/index';
import { setTriggersTableName } from '_Resources/Triggers/Actions/Filters/setTriggersTableName';
import { setTriggersTableDescription } from '_Resources/Triggers/Actions/Filters/setTriggersTableDescription';
import { setTriggersTableAnalysisAxis } from '_Resources/Triggers/Actions/Filters/setTriggersTableAnalysisAxis';
import { setTriggersTableItems } from '_Resources/Triggers/Actions/Filters/setTriggersTableItems';
import { setTriggersTableCalculationCriteria } from '_Resources/Triggers/Actions/Filters/setTriggersTableCalculationCriteria';
import { setTriggersTableThreshold } from '_Resources/Triggers/Actions/Filters/setTriggersTableThreshold';
import ItemsToDisplaySelect from './ItemsToDisplaySelect';

const StyledQModalBody = styled(QModalBody)`
  padding: 0 ${spacing.medium};
`;

const StyledQInput = styled(QInput)`
  width: 100%;
`;

const VolumeInput = styled(QInput)`
  width: 100%;
  padding: 0.5em 2em 0.5em 1em;
  font-size: 13px;
  height: 38px;
  border-radius: 20px;
`;

const StyledTextArea = styled(QInput)`
  width: 100%;
  resize: none;
`;

const StyledLabel = styled(Label)`
  color: ${grayShades.g800};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Cancel = styled.p`
  color: ${grayShades.g800};
  font-size: ${fontSizes.smaller};
  cursor: pointer;
  font-weight: bold;
`;

const CreateButton = styled(QButton)`
  margin: 0 ${spacing.xsmall} 0 ${spacing.large} !important;
`;

const Description = styled(QTitle)`
  font-size: 1em;
  padding: 0;
  margin: 0;
`;

const SelectContainer = styled.div`
  width: calc(50% - 0.5rem);
  font-weight: bold;
`;

const StyledSelect = styled(QSelect)``;

const SelectFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: space-between;
`;

const PercentageInputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PercentageSign = styled.span`
  position: absolute;
  right: 2.5rem;
`;

class InputNameModal extends React.Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    triggersTable: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        analysisAxis: PropTypes.string,
        period: PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ),
    footer: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    currentProject: PropTypes.shape.isRequired,
  };

  static defaultProps = {
    triggersTable: null,
  };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      triggersTableName: get(this.props.triggersTable, 'title') || '',
      triggersTableDescription: get(this.props.triggersTable, 'description') || '',
      triggersTableAnalysisAxis: get(this.props.triggersTable, 'analysisAxis') || '',
      triggersTableItemsToDisplay: get(this.props.triggersTable, 'itemsToDisplay') || '',
      triggersTableCalculationCriteria: get(this.props.triggersTable, 'calculationCriteria') || '',
      triggersTableVolumeThreshold: get(this.props.triggersTable, 'volumeThreshold') || '',
      triggersTablePeriod: get(this.props.triggersTable, 'period') || { startDate: '', endDate: '' },
      step: 0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onAnalysisAxisChange = this.onAnalysisAxisChange.bind(this);
    this.onItemsToDisplayChange = this.onItemsToDisplayChange.bind(this);
    this.onCalculationCriteriaChange = this.onCalculationCriteriaChange.bind(this);
    this.onVolumeThresholdChange = this.onVolumeThresholdChange.bind(this);
    this.onPeriodStartChange = this.onPeriodStartChange.bind(this);
    this.onPeriodEndChange = this.onPeriodEndChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
  }

  onSubmit() {
    const {
      triggersTableName,
      triggersTableDescription,
      triggersTableAnalysisAxis,
      triggersTableItemsToDisplay,
      triggersTableCalculationCriteria,
      triggersTableVolumeThreshold,
      triggersTablePeriod,
    } = this.state;
    const { temporaryTriggers, currentProject, triggersTable, toggle } = this.props;

    // if (this.state.triggersTableName.length > 0) {
      // console.log('submit');
      // console.log('triggersTable :', triggersTable);
      // console.log('triggersTableName : ', triggersTableName);
      // console.log('triggersTableDescription : ', triggersTableDescription);
      // console.log('triggersTableAnalysisAxis.value : ', triggersTableAnalysisAxis.value);
      // console.log('triggersTableItemsToDisplay : ', triggersTableItemsToDisplay);
      // console.log('triggersTableCalculationCriteria : ', triggersTableCalculationCriteria);
      // console.log('triggersTableVolumeThreshold : ', triggersTableVolumeThreshold);
      // console.log('temporaryTriggers.settings.range : ', temporaryTriggers.settings.range);
      // console.log('currentProject : ', currentProject);
      toggle();
      // this.setState({ triggersTableName: '' });
      // this.setState({ triggersTableDescription: '' });
    // }
  }

  onNameChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableName } = this.props;
    this.setState({ triggersTableName: e.target.value });
    dispatchSetTriggersTableName(e.target.value);
  }

  onDescriptionChange(e: { target: { value: any } }) {
    const { dispatchSetTriggersTableDescription } = this.props;
    this.setState({ triggersTableDescription: e.target.value });
    dispatchSetTriggersTableDescription(e.target.value);
  }

  onAnalysisAxisChange(e: any) {
    const { dispatchSetTriggersTableAnalysisAxis } = this.props;
    this.setState({ triggersTableAnalysisAxis: e });
    dispatchSetTriggersTableAnalysisAxis(e.value);
  }

  onItemsToDisplayChange(e: any) {
    const { dispatchSetTriggersTableItems } = this.props;
    this.setState({ triggersTableItemsToDisplay: e });
    dispatchSetTriggersTableItems(e);
  }

  onCalculationCriteriaChange(e: any) {
    const { dispatchSetTriggersTableCalculationCriteria } = this.props;
    this.setState({ triggersTableCalculationCriteria: e });
    dispatchSetTriggersTableCalculationCriteria(e);
  }

  onVolumeThresholdChange(e: { target: { value: string } }) {
    const { dispatchSetTriggersTableThreshold } = this.props;
    if (!isNaN(e.target.value)) {
      this.setState({ triggersTableVolumeThreshold: e.target.value });
      dispatchSetTriggersTableThreshold(parseFloat(e.target.value));
    }
  }

  onPeriodStartChange(e: { target: { value: any } }) {
    this.setState({ triggersTablePeriod: { startDate: e.target.value } });
  }

  onPeriodEndChange(e: { target: { value: any } }) {
    this.setState({ triggersTablePeriod: { endDate: e.target.value } });
  }

  onCancel() {
    const { toggle } = this.props;
    toggle();
    this.setState({ step: 0 });
  }

  getItemsToDisplayVariable(
    itemsToDisplay: { id?: String; label: String; value: String; values?: [String] },
    itemsToDisplayOptions: [
      { label: String; value: String; subOptions?: [{ id: String; label: String; values: [String] }] },
    ],
  ): { name: String; key?: String } {
    if (itemsToDisplay.value) {
      return { name: itemsToDisplay.value };
    }
    const optionsWithSubOptions = itemsToDisplayOptions.filter(option => option.subOptions);

    if (optionsWithSubOptions && optionsWithSubOptions?.length > 0) {
      const subOptionParentValue = optionsWithSubOptions.find(option =>
        option.subOptions.find(
          (subOption: { id: String; label: String; values: [String] }) => subOption.id === itemsToDisplay.id,
        ),
      )?.value;

      if (typeof subOptionParentValue === 'string') {
        return { name: subOptionParentValue, key: itemsToDisplay.label };
      }
    }
    return { name: '' };
  }

  getThematicsVariable() {
    const { settings: triggersSettings } = this.props.temporaryTriggers;
    const { thematics } = triggersSettings;

    const thematicsVariable = thematics.map((thematic: { option: { category: any; label: any }; parents: any[] }) => {
      switch (thematic.option.category) {
        case 'Thematic':
          return { thematic: thematic.option.label };
        case 'Sub thematic':
          return { thematic: thematic.parents[1], subThematic: thematic.option.label };
        case 'Sub sub thematic':
          return {
            thematic: thematic.parents[1],
            subThematic: thematic.parents[2],
            subSubThematic: thematic.option.label,
          };
        default:
          return { thematic: thematic.option.label };
      }
    });

    return thematicsVariable;
  }

  getCriteriaKeysVariable() {
    const { temporaryTriggers } = this.props;
    const { settings: triggersSettings } = temporaryTriggers;
    const { criteriaKeys } = triggersSettings;
    const criteriaKeysCategories = uniq(criteriaKeys.map((key: { filterCategory: string }) => key.filterCategory));
    const criteriaKeysArray: any[] = [];
    criteriaKeysCategories.forEach((category: string) => {
      criteriaKeys.map((key: { filterCategory: string; option: { label: any } }) => {
        if (key.filterCategory === category) {
          const criteriaKeysFoundCategory = criteriaKeysArray.find(criteriaKey => criteriaKey.name === category);
          if (!criteriaKeysFoundCategory) {
            criteriaKeysArray.push({ name: key.filterCategory, values: [] });
          }
          criteriaKeysArray.find(criteriaKey => criteriaKey.name === category).values.push(key.option.label);
        }
        return criteriaKeysArray;
      });
    });

    return criteriaKeysArray;
  }

  getAddFooter(
    modalConfig: { cancelButton: any; validateButton: any; steps: any },
    currentStep: number,
    itemsToDisplayOptions: [
      { value: String; label: String; subOptions?: [{ id: String; label: String; values: [String] }] },
    ],
  ) {
    const { currentProject, t, temporaryTriggers } = this.props;
    const {
      triggersTableName,
      triggersTableDescription,
      triggersTableAnalysisAxis,
      triggersTableCalculationCriteria,
      triggersTableVolumeThreshold: stateTriggersTableVolumeThreshold,
    } = this.state;
    const { steps } = modalConfig;
    const { settings: triggersSettings } = temporaryTriggers;
    const { itemsToDisplay, volumeThreshold: triggersTableVolumeThreshold, range } = triggersSettings;

    return (
      <Footer>
        <StyledQButton hide={!steps[currentStep - 1]} onClick={this.prevStep}>
          {steps[currentStep].prevButtonText || t('Prev. step')}
        </StyledQButton>
        <StyledQButton onClick={() => this.onCancel()}>{t('button:Cancel')}</StyledQButton>
        {steps[currentStep + 1] && (
          <StyledQButton hide={!steps[currentStep + 1]} onClick={this.nextStep}>
            {steps[currentStep].nextButtonText || t('Next step')}
          </StyledQButton>
        )}
        {!steps[currentStep + 1] && (
          <MutationSaveTriggersTable
            variables={{
              projectId: currentProject && currentProject.id,
              title: triggersTableName,
              description: triggersTableDescription,
              analysisAxis: triggersTableAnalysisAxis.value,
              itemsToDisplay: this.getItemsToDisplayVariable(itemsToDisplay, itemsToDisplayOptions),
              calculationCriteria: triggersTableCalculationCriteria.value,
              significanceThreshold: parseFloat(stateTriggersTableVolumeThreshold),
              range,
              thematics: this.getThematicsVariable(),
              criteriaKeys: this.getCriteriaKeysVariable(),
            }}
            callBackAction={this.onSubmit}
          >
            <CreateButton
              icon="CHECK_ALL"
              iconSize={20}
              iconPosition="left"
              disabled={!(triggersTableName.length > 0) || typeof parseFloat(triggersTableVolumeThreshold) !== 'number'}
            >
              &nbsp; {t('button:Create triggers table')}
            </CreateButton>
          </MutationSaveTriggersTable>
        )}
      </Footer>
    );
  }

  getRenameModalFooter() {
    const { t, triggersTable, toggle } = this.props;
    const { triggersTableName, triggersTableDescription } = this.state;
    return (
      <Footer>
        <Cancel onClick={() => toggle()}>{t('button:Cancel')}</Cancel>
        <MutationSaveTriggersTable
          variables={{
            id: triggersTable.id,
            title: triggersTableName,
            description: triggersTableDescription,
          }}
          callBackAction={this.onSubmit}
        >
          <CreateButton>{t('button:Edit this triggers table')}</CreateButton>
        </MutationSaveTriggersTable>
      </Footer>
    );
  }

  nextStep() {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  }

  prevStep() {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  }

  initModalValues = {
    config: {
      date: false,
      mentions: false,
    },
  };

  render() {
    const propsModal = this.initModalValues;
    const { t, criteriaKeysOptions, temporaryTriggers, triggersTable, isOpen, title, toggle, subtitle } = this.props;
    const { triggersTableName, step, triggersTableAnalysisAxis, triggersTableCalculationCriteria, triggersTableVolumeThreshold, triggersTableDescription } = this.state;
    const { settings: triggersSettings } = temporaryTriggers;
    const { itemsToDisplay } = triggersSettings;

    const analysisAxisOptions = [
      {
        value: 'emotion',
        label: t('Emotion'),
      },
      {
        value: 'sentiment',
        label: t('button:Sentiment'),
      },
      {
        value: 'verbatims_selection',
        label: t('Verbatim selection'),
      },
      {
        value: 'satisfaction',
        label: t('Satisfaction'),
      },
      {
        value: 'effort',
        label: t('Customer Effort Score'),
      },
      {
        value: 'reco',
        label: t('Recommendation'),
      },
      {
        value: 'nps',
        label: t('Net Promoter Score'),
      },
    ];

    const itemsToDisplayOptions = [
      {
        value: 'thematic',
        label: t('Thematics'),
      },
      {
        value: 'sub_thematic',
        label: t('Sub thematics'),
      },
      {
        value: 'sub_sub_thematic',
        label: t('Sub sub thematics'),
      },
      // {
      //   value: 'clusters',
      //   label: t('Irritants & Enchanting points'),
      // },
      {
        value: 'criteria_key',
        label: t('Criteria keys values'),
        subOptions: criteriaKeysOptions,
      },
    ];

    const calculationCriteriaOptions = [
      {
        value: 'percentage',
        label: t('Percentage'),
      },
      {
        value: 'volume',
        label: t('Volume'),
      },
    ];

    const MODAL_CONTENT_CONFIG = {
      cancelButton: {
        text: t('button:Cancel'),
      },
      validateButton: {
        text: t('button:Create this triggers table'),
        disabled: !triggersTableName,
      },
      steps: [
        {
          title: 'Step 0',
          stepBody: (
            <>
              {!triggersTable && (
                <>
                  <Description>{t('Name and describe your new triggers table')}</Description>
                  <hr />
                </>
              )}
              <FormGroup>
                <StyledLabel>{t('Name of triggers table')}</StyledLabel> <br />
                <StyledQInput primary maxLength={50} value={triggersTableName} onChange={this.onNameChange} />
              </FormGroup>
              <SelectFormGroup>
                <SelectContainer>
                  <StyledLabel>{t('Analysis axis')}</StyledLabel> <br />
                  <StyledSelect
                    name="analysisAxisSelect"
                    value={triggersTableAnalysisAxis}
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('button:Analysis axis')}
                    onChange={this.onAnalysisAxisChange}
                    options={analysisAxisOptions}
                  />
                </SelectContainer>
                <SelectContainer>
                  <StyledLabel>{t('Items to display')}</StyledLabel> <br />
                  <ItemsToDisplaySelect
                    value={itemsToDisplay}
                    onChange={this.onItemsToDisplayChange}
                    options={itemsToDisplayOptions}
                  />
                </SelectContainer>
              </SelectFormGroup>
              <SelectFormGroup>
                <SelectContainer>
                  <StyledLabel>{t('Calculation criteria')}</StyledLabel> <br />
                  <StyledSelect
                    name="calculationCriteriaSelect"
                    value={triggersTableCalculationCriteria}
                    className="selectInput"
                    searchable={false}
                    clearable={false}
                    placeholder={t('button:Calculation criteria')}
                    onChange={this.onCalculationCriteriaChange}
                    options={calculationCriteriaOptions}
                  />
                </SelectContainer>
                <SelectContainer>
                  <StyledLabel>{t('Volume threshold')}</StyledLabel> <br />
                  <PercentageInputContainer>
                    <VolumeInput
                      primary
                      maxLength={4}
                      value={triggersTableVolumeThreshold}
                      onChange={this.onVolumeThresholdChange}
                    />
                    <PercentageSign> %</PercentageSign>
                  </PercentageInputContainer>
                </SelectContainer>
              </SelectFormGroup>
              <FormGroup>
                <StyledLabel>{t('Description (optional)')}</StyledLabel> <br />
                <StyledTextArea
                  as="textarea"
                  primary
                  rows={2}
                  value={triggersTableDescription}
                  onChange={this.onDescriptionChange}
                  maxLength={175}
                />
              </FormGroup>
            </>
          ),
          nextButtonText: t('button:Next step'),
        },
        {
          title: 'Step 1',
          stepBody: (
            <ModalFiltersPage
              stepTitle={t('Choose your filters')}
              columnCollapseContent={columnCollapseContent} // ok (tuto)
              context={CONTEXT.TRIGGERS} // OK
              currentSettingsFilters={triggersSettings} // OK
            />
          ),
          prevButtonText: t('button:Prev. step'),
        },
      ],
    };

    return (
      <QModal
        footer={
          triggersTable
            ? this.getRenameModalFooter()
            : this.getAddFooter(MODAL_CONTENT_CONFIG, step, itemsToDisplayOptions)
        }
        isOpen={isOpen}
        title={t(title)}
        onClose={toggle}
        subtitle={t(subtitle)}
        {...propsModal}
      >
        <StyledQModalBody>{MODAL_CONTENT_CONFIG.steps[step].stepBody}</StyledQModalBody>
      </QModal>
    );
  }
}

const mapStateToProps = (state: { triggers: { projectTriggersTables: any }; projects: { currentProject: any } }) => ({
  triggersTablesList: state.triggers?.projectTriggersTables,
  currentProject: state.projects.currentProject,
  temporaryTriggers: state.triggers?.temporaryTriggers,
  criteriaKeysOptions: state.filters?.projectFilters?.criteriaKeys?.normal,
});

const mapDispatchToProps = (dispatch: (arg0: { type: string; data: any }) => void) => ({
  dispatchSetTriggersTableName: (newState: any) => {
    dispatch(setTriggersTableName(newState));
  },
  dispatchSetTriggersTableDescription: (newState: any) => {
    dispatch(setTriggersTableDescription(newState));
  },
  dispatchSetTriggersTableAnalysisAxis: (newState: any) => {
    dispatch(setTriggersTableAnalysisAxis(newState));
  },
  dispatchSetTriggersTableItems: (newState: any) => {
    dispatch(setTriggersTableItems(newState));
  },
  dispatchSetTriggersTableCalculationCriteria: (newState: any) => {
    dispatch(setTriggersTableCalculationCriteria(newState));
  },
  dispatchSetTriggersTableThreshold: (newState: any) => {
    dispatch(setTriggersTableThreshold(newState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('triggers', 'button')(InputNameModal));
