import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import QSelect from '_Components/Fields/QSelect/QSelect';
import QInput from '_Components/Fields/QInput/QInput';
import IconCircle from '../../../../../../../_Components/Icons/IconCircle/IconCircle';
import { red } from '../../../../../../../styles/abstracts/colors';
import { ImageSize } from '../../../../../../../styles/abstracts/variables';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  justify-content: space-between;
`;

const Selects = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
`;

const SelectContainer = styled.div`
  min-width: 10em;
  margin-right: 1em;
  margin-bottom: 0.5em;
`;

export const IDENTIFIER_OPTIONS = [
  { value: 'emotions', label: 'Main emotion' },
  { value: 'thematic', label: 'Category' },
  { value: 'criteria_key', label: 'Criteria key' },
  { value: 'keyword', label: 'Keywords' },
];

const EMOTIONS_OPTIONS = [
  { value: 'happiness', label: 'Happiness' },
  { value: 'surprise', label: 'Surprise' },
  { value: 'calm', label: 'Calm' },
  { value: 'fear', label: 'Fear' },
  { value: 'sadness', label: 'Sadness' },
  { value: 'anger', label: 'Anger' },
  { value: 'disgust', label: 'Disgust' },
];

const INCLUDE_OPTIONS = [
  { value: false, label: 'is not equal to' },
  { value: true, label: 'is equal to' },
];

type ConditionProps = {
  condition: any | undefined;
  criteriaKeys: Array<any>;
  conditions: Array<any>;
  setConditions: any;
  index: number;
  isAlert: boolean;
  selector: 'and' | 'or';
  t: any;
  thematics: any;
};

const Condition = ({
  condition,
  criteriaKeys,
  conditions,
  setConditions,
  index,
  isAlert,
  selector,
  t,
  thematics,
}: ConditionProps) => {
  const getTranslatedValue = options => {
    return options.map(opt => ({ ...opt, label: t(opt.label) }));
  };

  const [identifier, setIdentifier] = useState(
    getTranslatedValue(IDENTIFIER_OPTIONS).find(ident => ident.value === condition?.identifier) || undefined,
  );
  const [identifierName, setIdentifierName] = useState(
    criteriaKeys
      ?.map(ck => ({
        value: ck?.id,
        label: ck?.label,
        children: ck?.values,
      }))
      .find(ck => ck.label === condition?.name) || null,
  );
  const [includeOption, setIncludeOption] = useState(
    getTranslatedValue(INCLUDE_OPTIONS)?.find(incl => incl.value === condition?.include),
  );

  const getConditionValue = (isNext: boolean = false) => {
    switch ((isNext ? conditions?.[index + 1] : condition)?.identifier || identifier?.value) {
      case 'emotions':
        return getTranslatedValue(EMOTIONS_OPTIONS).find(
          emotion => emotion.value === (isNext ? conditions?.[index + 1] : condition)?.value,
        );
      case 'thematic':
        return getThematic(
          (isNext ? conditions?.[index + 1] : condition)?.level,
          (isNext ? conditions?.[index + 1] : condition)?.value,
        )?.subSubThematic;
      default:
        return (isNext ? conditions?.[index + 1] : condition)?.value || '';
    }
  };

  const getThematic = (level: string, value: any) => {
    const formattedThematics = thematics?.map(th => ({
      value: th.id,
      label: th.label,
      children: th.values,
    }));
    if (level === 'thematic') {
      return {
        thematic: formattedThematics?.find(th => th.value === value),
        subThematic: null,
        subSubThematic: null,
      };
    }
    if (level === 'subThematic') {
      return {
        thematic: formattedThematics.find(th => th.children.find(subth => subth.id === value)),
        subThematic: formattedThematics
          .find(th => th.children.find(subth => subth.id === value))
          ?.children?.map(th => ({
            value: th.id,
            label: th.label,
            children: th.values,
          }))
          .find(th => th.value === value),
        subSubThematic: null,
      };
    }
    if (level === 'subSubThematic') {
      const subThematic = formattedThematics
        .find(th => th.children.find(subth => subth.values.find(subsubth => subsubth.id === value)))
        .children.map(th => ({
          value: th.id,
          label: th.label,
          children: th.values,
        }))
        .find(th => th.children.find(subth => subth.id === value));
      return {
        thematic: formattedThematics.find(th =>
          th.children.find(subth => subth.values.find(subsubth => subsubth.id === value)),
        ),
        subThematic,
        subSubThematic: subThematic?.children
          .map(th => ({
            value: th.id,
            label: th.label,
          }))
          .find(th => th.value === value),
      };
    }
  };

  const [value, setValue] = useState<any>(getConditionValue());
  const [level, setLevel] = useState<any>(condition?.level);
  const [subIdentifier, setSubIdentifier] = useState<any>(getThematic(condition?.level, condition?.value)?.thematic);
  const [subSubIdentifier, setSubSubIdentifier] = useState<any>(
    getThematic(condition?.level, condition?.value)?.subThematic,
  );
  useEffect(() => {
    const newConditions = cloneDeep(conditions);
    newConditions[index] = value
      ? {
          identifier: identifier?.value,
          value: value?.value || value,
          name: identifierName,
          include: includeOption?.value,
          selector,
          level,
        }
      : null;
    setConditions(newConditions);
  }, [identifier, identifierName, includeOption, value, selector]);
  // @ts-ignore
  return (
    <Container>
      <Selects>
        <SelectContainer>
          <QSelect
            disabled={isAlert}
            options={
              conditions?.find(cdt => cdt?.selector === 'and' && cdt?.identifier === 'emotions') && selector === 'and'
                ? getTranslatedValue(IDENTIFIER_OPTIONS.filter(opt => opt.value !== 'emotions'))
                : getTranslatedValue(IDENTIFIER_OPTIONS)
            }
            onChange={(ident: { value: string; label: string }) => {
              setIdentifier(ident);
              setIdentifierName(null);
              setIncludeOption(null);
              setValue(null);
            }}
            value={identifier}
            config={{ placeholder: t('Select') }}
          />
        </SelectContainer>
        {identifier && identifier?.value === 'criteria_key' && (
          <SelectContainer>
            <QSelect
              disabled={isAlert}
              options={criteriaKeys?.map(key => ({
                value: key?.id,
                label: key?.label,
                children: key?.values,
              }))}
              onChange={val => {
                setIdentifierName(val);
                setValue('');
                setIncludeOption({ label: t('is not equal to'), value: false });
              }}
              value={identifierName}
              config={{ placeholder: t('Select') }}
            />
          </SelectContainer>
        )}
        {identifier &&
          ((identifier.value === 'criteria_key' && identifierName?.value) || identifier.value !== 'criteria_key') && (
            <SelectContainer>
              <QSelect
                disabled={isAlert}
                onChange={(val: { value: boolean; label: string }) => {
                  setIncludeOption(val);
                }}
                options={getTranslatedValue(INCLUDE_OPTIONS)}
                config={{ placeholder: t('Select') }}
                value={includeOption}
              />
            </SelectContainer>
          )}
        {includeOption &&
          identifier &&
          (identifier.value === 'criteria_key' ? (
            <SelectContainer>
              <QSelect
                disabled={isAlert}
                value={{
                  value: identifierName.children.find(child => child === (value.value || value)),
                  label: identifierName.children.find(child => child === (value.value || value)),
                }}
                options={identifierName.children?.map((child: string) => ({
                  value: child,
                  label: child,
                }))}
                onChange={(val: { value: string; label: string }) => setValue(val)}
              />
            </SelectContainer>
          ) : identifier.value === 'thematic' ? (
            <SelectContainer>
              <QSelect
                disable={isAlert}
                options={thematics.map(th => ({
                  value: th.id,
                  label: th.label,
                  children: th.values,
                }))}
                value={subIdentifier}
                onChange={val => {
                  setSubIdentifier(val);
                  setValue(val);
                  setLevel('thematic');
                }}
                config={{ placeholder: t('Select') }}
              />
            </SelectContainer>
          ) : identifier.value === 'emotions' ? (
            <SelectContainer>
              <QSelect
                disabled={isAlert}
                onChange={(val: { value: string; label: string }) => setValue(val)}
                options={getTranslatedValue(EMOTIONS_OPTIONS)}
                value={value}
              />
            </SelectContainer>
          ) : (
            <SelectContainer>
              <QInput disabled={isAlert} value={value?.value || value || ''} onChange={e => setValue(e.target.value)} />
            </SelectContainer>
          ))}
        {subIdentifier?.value && subIdentifier?.children?.length > 0 && (
          <SelectContainer>
            <QSelect
              disabled={isAlert}
              options={subIdentifier?.children?.map(th => ({
                value: th.id,
                label: th.label,
                children: th.values,
              }))}
              value={subSubIdentifier}
              onChange={val => {
                setValue(val);
                setSubSubIdentifier(val);
                setLevel('subThematic');
              }}
              config={{ placeholder: t('button:All') }}
            />
          </SelectContainer>
        )}
        {subSubIdentifier?.value && subSubIdentifier?.children?.length > 0 && (
          <SelectContainer>
            <QSelect
              disabled={isAlert}
              options={subSubIdentifier?.children?.map(th => ({
                value: th.id,
                label: th.label,
              }))}
              onChange={val => {
                setValue(val);
                setLevel('subSubThematic');
              }}
              config={{ placeholder: t('button:All') }}
              value={level === 'subSubThematic' && value}
            />
          </SelectContainer>
        )}
      </Selects>

      {conditions.length > 1 && conditions[index] !== null && (
        <IconCircle
          color={red}
          icon="CROSS"
          size={ImageSize.medium}
          pointer
          onClick={() => {
            setConditions(conditions.filter((cond, ind) => ind !== index));
            if (!conditions[index + 1]) {
              setIdentifierName(null);
              setSubIdentifier(null);
              setValue(null);
              setIncludeOption(false);
              setIdentifier(null);
              setLevel(null);
              setSubSubIdentifier(null);
            } else {
              setIdentifier(
                getTranslatedValue(IDENTIFIER_OPTIONS).find(
                  ident => ident.value === conditions?.[index + 1]?.identifier,
                ),
              );
              if (conditions[index + 1]?.identifier === 'criteria_key') {
                const selectedCriteriaKey = criteriaKeys
                  .map(key => ({
                    value: key?.id,
                    label: key?.label,
                    children: key?.values,
                  }))
                  .find(key => key.value === conditions?.[index + 1]?.name?.value);
                setSubIdentifier(null);
                setLevel(null);
                setSubSubIdentifier(null);
                setIdentifierName(selectedCriteriaKey);
                setValue(
                  selectedCriteriaKey?.children
                    ?.map(key => ({
                      value: key,
                      label: key,
                    }))
                    .find(key => key.value === conditions?.[index + 1]?.value),
                );
              } else if (conditions[index + 1]?.identifier === 'thematic') {
                setLevel(conditions[index + 1]?.level);
                if (conditions[index + 1]?.level === 'thematic') {
                  setSubIdentifier(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.thematic);
                  setValue(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.thematic);
                } else if (conditions[index + 1]?.level === 'subThematic') {
                  setSubIdentifier(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.thematic);
                  setSubSubIdentifier(
                    getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.subThematic,
                  );
                  setValue(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.subThematic);
                } else if (conditions[index + 1]?.level === 'subSubThematic') {
                  setSubIdentifier(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.thematic);
                  setSubSubIdentifier(
                    getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.subThematic,
                  );
                  setValue(getThematic(conditions[index + 1]?.level, conditions[index + 1]?.value)?.subSubThematic);
                }
              } else {
                setValue(getConditionValue(true));
                setSubIdentifier(null);
                setLevel(null);
                setSubSubIdentifier(null);
              }
              setIncludeOption(
                getTranslatedValue(INCLUDE_OPTIONS)?.find(incl => incl.value === conditions?.[index + 1]?.include),
              );
            }
          }}
        />
      )}
    </Container>
  );
};

export default Condition;
