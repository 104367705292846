import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment/moment';
import { withTranslation } from 'react-i18next';
import { themeColors } from 'styles/abstracts/colors';
import { getFontSizeStyles } from 'styles/abstracts/typography';

const SubTitle = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${themeColors.secondary} ${getFontSizeStyles(14)};
  white-space: pre-line;
`;

const CardSubTitle = props => {
  const { numberOfMentionsQuery, numberOfMentionsForced, period, t, triggersSection, triggersPeriod } = props;
  const getFullDate = date => {
    const periodToConsider = triggersSection && triggersPeriod.startDate !== '' ? triggersPeriod : period;
    // const periodToConsider = period;
    const dateFormat = moment(periodToConsider[date]);
    dateFormat?.locale(props.i18n.language);
    return dateFormat.format('LL');
  };
  let startFilterDate;
  let endFilterDate;
  if (period) {
    startFilterDate = getFullDate('startDate');
    endFilterDate = getFullDate('endDate');
  }
  const getFullReferenceDate = date => {
    const dateFormat = moment(props.referencePeriod[date]);
    dateFormat.locale(props.i18n.language);
    return dateFormat.format('LL');
  };
  let startReferenceDate;
  let endReferenceDate;
  if (props.referencePeriod) {
    startReferenceDate = getFullReferenceDate('startDate');
    endReferenceDate = getFullReferenceDate('endDate');
  }
  const mentionsToDisplay = typeof numberOfMentionsForced === 'number' ? numberOfMentionsForced : numberOfMentionsQuery;
  return (
    <SubTitle id={`${props.title}-subtitle`}>
      {props.subtitleAdditionalContent && !triggersSection && t(props.subtitleAdditionalContent)}
      {props.subtitleAdditionalContent &&
        !triggersSection &&
        mentionsToDisplay > -1 &&
        typeof mentionsToDisplay === 'number' &&
        props.displayMentionsSubtitle && <br />}
      {mentionsToDisplay > -1 && typeof mentionsToDisplay === 'number' && props.displayMentionsSubtitle
        ? `${mentionsToDisplay} ${t('mention', {
            count: parseInt(mentionsToDisplay),
          })} ${
            props.referenceMentions
              ? `(vs ${props.referenceMentions} ${t('mention', {
                  count: parseInt(props.referenceMentions),
                })})`
              : ''
          }`
        : ''}
      {mentionsToDisplay > -1 &&
      typeof mentionsToDisplay === 'number' &&
      (startFilterDate || endFilterDate) &&
      props.displayMentionsSubtitle &&
      props.displayDateSubtitle ? (
        <br />
      ) : (
        ''
      )}
      {(startFilterDate || endFilterDate) &&
        props.displayDateSubtitle &&
        `${t('From')} ${startFilterDate} ${t('to')} ${endFilterDate} ${
          props.referencePeriod ? `(vs ${t('From')} ${startReferenceDate} ${t('to')} ${endReferenceDate})` : ''
        }`}
      {props.subtitleAdditionalContent && triggersSection && (
        <>
          <br /> {t(props.subtitleAdditionalContent)}
        </>
      )}
    </SubTitle>
  );
};

CardSubTitle.defaultProps = {
  numberOfMentionsQuery: '',
  numberOfMentionsForced: null,
  startFilterDate: '',
  endFilterDate: '',
  displayMentionsSubtitle: true,
  displayDateSubtitle: true,
  subtitleAdditionalContent: null,
  triggersPeriod: {
    startDate: '',
    endDate: '',
  },
  triggersSection: false,
};
CardSubTitle.propTypes = {
  numberOfMentionsQuery: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberOfMentionsForced: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startFilterDate: PropTypes.string,
  endFilterDate: PropTypes.string,
  displayMentionsSubtitle: PropTypes.bool,
  displayDateSubtitle: PropTypes.bool,
  subtitleAdditionalContent: PropTypes.string,
  triggersPeriod: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  triggersSection: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    period: state.periods.currentPeriod,
    triggersPeriod: state.triggers.activeTriggersTable?.settings?.range,
    numberOfMentionsQuery: state.mentions.currentMentionCountWithoutNull,
  };
}
export default connect(mapStateToProps)(withTranslation('card')(CardSubTitle));
