import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { cloneDeep, findKey, flattenDeep, upperFirst } from 'lodash';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  ToggleButton,
  Menu,
  Label,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import { grayShades, purple } from 'styles/abstracts/colors';
import QCheckbox from '_Components/Fields/QCheckbox/QCheckbox';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { INDICATORS_TYPE } from '_Cards/FilterCards/DataByFiltersCard/FiltersSettings';

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
`;

const StyledItem = styled(Item)`
  justify-content: flex-start !important;
`;

const StyledQCheckbox = styled(QCheckbox)`
  margin: 0 1em 0 0 !important;
`;

type DataByThematicsDropdownProps = {
  icon: string;
  id: string;
  label: string;
  t: any;
  itemToIterate: Array<any>;
  settings: any;
  setSettings: any;
  dropdownType: string;
  defaultSettings: any;
  subCategory: any;
  orderByItems: Array<any>;
};

const DataByThematicsDropdown = ({
  icon,
  id,
  label,
  t,
  itemToIterate,
  settings,
  setSettings,
  dropdownType,
  defaultSettings,
  subCategory,
  orderByItems,
}: DataByThematicsDropdownProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const getCurrentItem = () => {
    if (itemToIterate[1].subcategories) {
      let allValues: any[] = [];
      flattenDeep(itemToIterate.map(item => item.subcategories)).map(item => {
        if (item.subcategories) {
          allValues = [...allValues, ...item.subcategories];
        } else {
          allValues = [...allValues, item];
        }
      });
      return allValues.find(item => {
        return defaultSettings ? item.id === defaultSettings[dropdownType].id : itemToIterate[1].subcategories[1];
      });
    }
    if (subCategory) {
      return defaultSettings ? defaultSettings[dropdownType][subCategory] : itemToIterate[1];
    }
    return itemToIterate.find(item => item.id === defaultSettings?.[dropdownType]) || itemToIterate[1];
  };
  const [currentItem, setCurrentItem] = useState(
    defaultSettings
      ? subCategory
        ? defaultSettings[dropdownType][subCategory]
        : typeof defaultSettings[dropdownType] === 'string'
        ? itemToIterate.find(item => item.id === defaultSettings?.[dropdownType])
        : defaultSettings[dropdownType]
      : getCurrentItem(),
  );
  const [dropdownContent, setDropdownContent] = useState<any[] | null>(null);
  const settingsToChange = findKey(settings, it => JSON.stringify(it) === JSON.stringify(defaultSettings));
  const [selected, setSelected] = useState<any[] | null>(currentItem?.selected);
  useEffect(() => {
    ((currentItem && !currentItem.label) || !currentItem) && setCurrentItem(itemToIterate[0]);
  }, [currentItem]);

  const selectItem = selectedValues => {
    const cloneSettings = cloneDeep(settings);
    const item = {
      id:
        selectedValues && selectedValues.length > 0
          ? selectedValues[0]?.split('.')[0]
          : dropdownContent[0].id.split('.')[0],
      label:
        selectedValues && selectedValues?.length > 1
          ? `${selectedValues.length} ${t(selectedValues[0].split('.')[0])}`
          : selectedValues && selectedValues.length === 1 && t(upperFirst(selectedValues[0].split('.')[1])),
      selected: selectedValues,
    };
    cloneSettings[settingsToChange][dropdownType][subCategory] = item;
    setSettings(cloneSettings);
    setSelected(selectedValues);
    setCurrentItem(item);
  };

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevSettings = usePrevious(defaultSettings);

  useEffect(() => {
    if (
      prevSettings &&
      defaultSettings &&
      JSON.stringify(prevSettings.indicators) !== JSON.stringify(defaultSettings.indicators) &&
      subCategory
    ) {
      if (defaultSettings && defaultSettings.orderBy && subCategory) {
        const [splitIndex, splitOrder] = (defaultSettings.orderBy.id || defaultSettings.orderBy.value).split('_');
        if (
          (defaultSettings.indicators?.[subCategory] &&
            defaultSettings.indicators?.[subCategory].id &&
            !defaultSettings.indicators?.[subCategory].id.includes(splitIndex)) ||
          (defaultSettings.indicators?.[subCategory] &&
            defaultSettings.indicators?.[subCategory].id &&
            !defaultSettings.indicators?.[subCategory].id.includes(splitIndex)) ||
          defaultSettings.indicators?.[subCategory].id === null
        ) {
          const cloneSettings = cloneDeep(settings);
          cloneSettings[settingsToChange].orderBy = orderByItems
            .find(
              item =>
                item.id ===
                (defaultSettings.indicators[
                  subCategory === INDICATORS_TYPE.BAR ? INDICATORS_TYPE.BADGE : INDICATORS_TYPE.BAR
                ].id === null
                  ? defaultSettings.indicators[subCategory].id?.split('.')[0]
                  : defaultSettings.indicators[
                      subCategory === INDICATORS_TYPE.BAR ? INDICATORS_TYPE.BADGE : INDICATORS_TYPE.BAR
                    ].id?.split('.')[0]),
            )
            ?.subcategories.find(sub => sub.id.split('_')[1] === splitOrder);
          setSettings(cloneSettings);
        }
      }
    }
    if (dropdownType === 'orderBy') {
      setCurrentItem(defaultSettings?.orderBy);
    }
  }, [defaultSettings]);

  return (
    <div id={id}>
      <Label>
        {icon && <Icon icon={icon} size={20} />} {t(label)}
      </Label>
      <StyledDropdown
        direction="down"
        isOpen={isDropdownOpen}
        toggle={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
      >
        <StyledDropdownToggle>
          <ToggleButton disableHover bgColor="grayShades.g300">
            {currentItem && t(currentItem.label).length > 16
              ? `${t(currentItem.label).substring(0, 16)}(...)`
              : currentItem && t(currentItem.label)}
            <Icon icon="INTERFACE_CHEVRON_DOWN" size={ImageSize.medium} />
          </ToggleButton>
        </StyledDropdownToggle>
        <Menu className="dataByFiltersDropdownMenu">
          {!dropdownContent ? (
            itemToIterate.map(item => {
              return (
                <Item
                  disabled={
                    (currentItem && item.id === currentItem.id && item.label === currentItem.label) ||
                    (dropdownType === 'orderBy' &&
                      (!defaultSettings?.indicators?.bar.id ||
                        (defaultSettings?.indicators?.bar.id &&
                          defaultSettings?.indicators?.bar.id.split('.')[0] !== item.id)) &&
                      (!defaultSettings?.indicators?.badge.id ||
                        (defaultSettings?.indicators?.badge.id &&
                          defaultSettings?.indicators?.badge.id.split('.')[0] !== item.id)) &&
                      item.id.split('_')[0] !== 'label' &&
                      item.id.split('_')[0] !== 'hierarchy')
                  }
                  toggle={!item.subcategories}
                  onClick={() => {
                    if (!item.subcategories) {
                      const cloneSettings = cloneDeep(settings);
                      if (subCategory) {
                        cloneSettings[settingsToChange][dropdownType][subCategory] =
                          dropdownType === 'selectedFilter' ? item.id : item;
                      } else {
                        cloneSettings[settingsToChange][dropdownType] =
                          dropdownType === 'selectedFilter' ? item.id : item;
                      }
                      setSelected(null);
                      setCurrentItem(item);
                      setSettings(cloneSettings);
                    } else {
                      setDropdownContent(item.subcategories);
                    }
                  }}
                >
                  {t(item.label)}
                  {item.subcategories && (
                    <Icon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      size={ImageSize.medium}
                      color={
                        (currentItem && item.id === currentItem.id && item.label === currentItem.label) ||
                        (dropdownType === 'orderBy' &&
                          (!defaultSettings?.indicators?.bar.id ||
                            (defaultSettings?.indicators?.bar.id &&
                              defaultSettings?.indicators?.bar.id.split('.')[0] !== item.id)) &&
                          (!defaultSettings?.indicators?.badge.id ||
                            (defaultSettings?.indicators?.badge.id &&
                              defaultSettings?.indicators?.badge.id.split('.')[0] !== item.id)) &&
                          item.id.split('_')[0] !== 'label' &&
                          item.id.split('_')[0] !== 'hierarchy')
                          ? grayShades.g800
                          : purple
                      }
                    />
                  )}
                </Item>
              );
            })
          ) : (
            <>
              <BackItem onClick={() => setDropdownContent(null)} toggle={false}>
                <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
                {t('Back')}
              </BackItem>
              {subCategory && !dropdownContent[0].subcategories && subCategory === 'bar' && (
                <StyledItem toggle={false}>
                  <StyledQCheckbox
                    selected={selected && selected.length === dropdownContent.length}
                    onClick={() => {
                      let selectedValues: string[] = [];
                      if (selected && selected.length === dropdownContent.length) {
                        selectedValues = [];
                      } else {
                        dropdownContent.map(content => {
                          selectedValues = [...selectedValues, content.id];
                        });
                      }
                      selectItem(selectedValues);
                    }}
                  />
                  {t('All emotions')}
                </StyledItem>
              )}
              {dropdownContent.map(content =>
                subCategory && !content.subcategories ? (
                  subCategory === 'bar' ? (
                    <StyledItem
                      toggle={false}
                      onClick={() => {
                        let selectedValues: string[] = [];
                        if (selected && selected.length > 0 && selected.find(sel => sel === content.id)) {
                          selectedValues = selected.filter(sel => sel !== content.id);
                        } else if (
                          selected &&
                          selected.length > 0 &&
                          selected[0].split('.')[2] === content.id.split('.')[2]
                        ) {
                          selectedValues = [...selected, content.id];
                        } else if (
                          !selected ||
                          (selected && selected.length === 0) ||
                          (selected && selected[0].split('.')[2] !== content.id.split('.')[2])
                        ) {
                          selectedValues = [content.id];
                        }
                        selectItem(selectedValues);
                      }}
                    >
                      <StyledQCheckbox selected={selected && selected.find(sel => sel === content.id)} />
                      {t(content.label)}
                    </StyledItem>
                  ) : (
                    <StyledItem
                      disabled={content.id === currentItem.id || content.label === currentItem.label}
                      onClick={() => {
                        const cloneSettings = cloneDeep(settings);
                        cloneSettings[settingsToChange][dropdownType][subCategory] = content;
                        setSelected(null);
                        setCurrentItem(content);
                        setSettings(cloneSettings);
                        setDropdownContent(null);
                      }}
                    >
                      {t(content.label)}
                    </StyledItem>
                  )
                ) : (
                  <StyledItem
                    alignLeft={!content.subcategories}
                    toggle={(content.subcategories || subCategory) && false}
                    disabled={
                      (currentItem && content.id === currentItem.id && currentItem.label === content.label) ||
                      (dropdownType === 'orderBy' &&
                        content.subcategories &&
                        !defaultSettings?.indicators?.bar.selected.find(sel => sel.split('.')[1] === content.id) &&
                        content.id.split('_')[0] !== 'label')
                    }
                    onClick={() => {
                      if (!content.subcategories) {
                        const cloneSettings = cloneDeep(settings);
                        cloneSettings[settingsToChange][dropdownType] = content;
                        setCurrentItem(content);
                        setSettings(cloneSettings);
                      } else {
                        setDropdownContent(content.subcategories);
                      }
                    }}
                  >
                    {content.subcategories ? (
                      <>
                        &nbsp;{t(content.label)}
                        <Icon
                          icon="INTERFACE_CHEVRON_RIGHT"
                          color={
                            (currentItem && content.id === currentItem.id && currentItem.label === content.label) ||
                            (dropdownType === 'orderBy' &&
                              content.subcategories &&
                              !defaultSettings?.indicators?.bar.selected.find(
                                sel => sel.split('.')[1] === content.id,
                              ) &&
                              content.id.split('_')[0] !== 'label')
                              ? grayShades.g800
                              : purple
                          }
                          size={ImageSize.medium}
                        />
                      </>
                    ) : (
                      dropdownType === 'orderBy' && (
                        <>
                          <Icon
                            icon={content.id.split('_')[1] === 'asc' ? 'INTERFACE_SORT_ASC' : 'INTERFACE_SORT_DESC'}
                            size={ImageSize.medium}
                            color={content?.id === currentItem?.id ? grayShades.g800 : purple}
                          />
                          &nbsp;{t(content.label)}
                        </>
                      )
                    )}
                  </StyledItem>
                ),
              )}
            </>
          )}
        </Menu>
      </StyledDropdown>
    </div>
  );
};

export default withTranslation('button')(DataByThematicsDropdown);
