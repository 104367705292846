import gql from 'graphql-tag';

const mutationDeleteEmotionalTriggerTable = gql`
  mutation deleteEmotionalTriggerTable($projectId: Int!, $id: Int!) {
    deleteEmotionalTriggerTable(projectId: $projectId, id: $id) {
      errors
      message
    }
  }
`;

export default mutationDeleteEmotionalTriggerTable;
