import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import {
  StyledDropdown,
  StyledDropdownToggle,
  Item,
  Menu,
} from '_Cards/FilterCards/DataByFiltersCard/_Containers/Dropdowns/utils/DropdownStyles';
import Icon from '_Components/Icons/Icon';
import { ImageSize } from 'styles/abstracts/variables';
import { grayShades, purple } from 'styles/abstracts/colors';

const StyledItem = styled(Item)<{ greyed: boolean; disabled: boolean }>`
  color: ${({ greyed, disabled }) => (greyed || disabled ? grayShades.g800 : purple)} !important;
  font-size: 1em !important;
`;

const BackItem = styled(Item)`
  justify-content: flex-start !important;
  padding-left: 0.25em !important;
  color: ${grayShades.g800} !important;
  font-size: 1em !important;
`;

const DropdownToggle = styled(StyledDropdownToggle)`
  width: 100%;
`;

const StyledDiv = styled.div<{ shadow: boolean }>`
  border-radius: 20px !important;
  background: ${grayShades.g300};
  ${({ shadow }) => shadow && 'box-shadow: 0 0 0 1px #2684ff;'}
  font-size: 13px;
  color: #592ea0;
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
`;

const StyledSpan = styled.span`
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}
`;

const ValueContainer = styled.div`
  padding: 2px 8px;
  flex: 1;
`;

const SingleValue = styled.div`
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
`;

const IndicatorsContainer = styled.div`
  border-radius: 20px !important;
  background: ${grayShades.g300};
  border: none;
  font-size: 13px;
  color: ${purple};
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
`;

const IndicatorContainer = styled.div`
  border-radius: 20px !important;
  background: ${grayShades.g300};
  border: none;
  font-size: 13px;
  color: ${purple};
  display: flex;
  padding: 8px;
  transition: color 150ms;
  box-sizing: border-box;
`;

const StyledMenu = styled(Menu)`
  border-radius: 20px !important;
  background: ${grayShades.g300};
  border: none;
  font-size: 13px;
  color: ${purple};
  width: 100%;
  overflow: auto;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  box-sizing: border-box;
  top: 100%;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const ItemsToDisplaySelect = ({ value, options, onChange, t }) => {
  const [dropdownContent, setDropdownContent] = useState<any[] | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const getCurrentItem = (options, value) => {
    const selectedOption = options.find(option => option.label === value.label);
    const selectedSubOption = options
      .find(option => option.subOptions)
      ?.subOptions.find(subOption => subOption.label === value.label);

    return selectedOption ?? selectedSubOption;
  };

  const [currentItem, setCurrentItem] = useState(getCurrentItem(options, value));

  return (
    <StyledDropdown
      direction="down"
      isOpen={isDropdownOpen}
      toggle={() => {
        setDropdownOpen(!isDropdownOpen);
      }}
    >
      <DropdownToggle>
        <StyledDiv shadow={isDropdownOpen}>
          <ValueContainer>
            <SingleValue>
              {currentItem && t(currentItem.label).length > 45
                ? `${t(currentItem.label).substring(0, 45)}(...)`
                : currentItem && t(currentItem.label)}
            </SingleValue>
          </ValueContainer>
          <IndicatorsContainer>
            <StyledSpan />
            <IndicatorContainer>
              <Icon icon="INTERFACE_CHEVRON_DOWN_SELECT" size={20} />
            </IndicatorContainer>
          </IndicatorsContainer>
        </StyledDiv>
      </DropdownToggle>
      <StyledMenu className="dataByFiltersDropdownMenu">
        {!dropdownContent ? (
          options.map(option => (
            <>
              <StyledItem
                disabled={option.label === currentItem?.label}
                greyed={
                  currentItem &&
                  option.subOptions &&
                  option.subOptions.find(subOption => subOption.label === currentItem.label)
                }
                alignLeft={!option.subOptions}
                toggle={option.subOptions && false}
                onClick={() => {
                  if (!option.subOptions) {
                    setCurrentItem(option);
                    onChange(option);
                  } else {
                    setDropdownContent(option.subOptions);
                  }
                }}
              >
                {option.subOptions ? (
                  <>
                    &nbsp;{t(option.label)}
                    <Icon
                      icon="INTERFACE_CHEVRON_RIGHT"
                      color={
                        currentItem && option.subOptions.find(subOption => subOption.label === currentItem.label)
                          ? grayShades.g800
                          : purple
                      }
                      size={ImageSize.medium}
                    />
                  </>
                ) : (
                  <>&nbsp;{t(option.label)}</>
                )}
              </StyledItem>
            </>
          ))
        ) : (
          <>
            <BackItem onClick={() => setDropdownContent(null)} toggle={false}>
              <Icon icon="INTERFACE_CHEVRON_LEFT" size={ImageSize.medium} color={grayShades.g800} />
              {t('Back')}
            </BackItem>
            {dropdownContent.map(content => {
              return (
                <StyledItem
                  disabled={content.label === currentItem?.label}
                  onClick={() => {
                    setCurrentItem(content);
                    onChange(content);
                    setDropdownContent(null);
                  }}
                >
                  {t(content.label)}
                </StyledItem>
              );
            })}
          </>
        )}
      </StyledMenu>
    </StyledDropdown>
  );
};

const mapStateToProps = (state: { triggers: { projectTriggersTables: any }; projects: { currentProject: any } }) => ({
  triggersTablesList: state.triggers?.projectTriggersTables,
  currentProject: state.projects.currentProject,
  temporaryTriggers: state.triggers?.temporaryTriggers,
  criteriaKeysOptions: state.filters?.projectFilters?.criteriaKeys?.normal,
});

export default connect(mapStateToProps)(withTranslation('triggers', 'button')(ItemsToDisplaySelect));
