import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEqual, get } from 'lodash';
import DatePickerContainer from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/DatePickerContainer';
import BenchmarkHeaderProjectFilters from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/Inputs/BenchmarkHeaderProjectFilters';
import { CONTEXT } from 'Routes/Benchmark/BenchmarkBoard/_Components/configs/globalConfig';
import GlobalFiltersValueProvider from '_Layouts/Header/_Containers/SubHeader/_Containers/HeaderProjectFilters/GlobalFiltersValueProvider';
import LatestBatchIndicator from '_Layouts/Header/_Containers/SubHeader/_Components/LatestBatchIndicator';
import DisplaySwitcher from './DisplaySwitcher';
import { setRangeFilter } from '../../../../../_Resources/Benchmark/Actions/Filters/setRangeFilter';
import { setCurrentPeriod } from '../../../../../_Resources/Global/Actions/setCurrentPeriod';
import SubHeader from '../../../../../_Layouts/Header/_Containers/SubHeader/SubHeader';
import ListTriggersContainer from './_Components/ListTriggersContainer';
import Project from '../../../../../_Layouts/Header/_Containers/SubHeader/_Containers/ListProjectContainer';
import { grayShades } from '../../../../../styles/abstracts/colors';
import { isValidFullDateRange } from '../../_Utils/checkDateRange';

const StyledHeaderProjectFilters = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;

const ContainInput = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  p {
    width: 5rem;
    color: ${grayShades.g800};
    font-weight: bold;
  }
`;

class TriggersSubHeader extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    projectPeriods: PropTypes.shape.isRequired,
    currentPeriod: PropTypes.shape.isRequired,
    settings: PropTypes.shape.isRequired,
    dispatchSetRangeFilter: PropTypes.func.isRequired,
    dispatchSetCurrentPeriod: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { dispatchSetCurrentPeriod, projectPeriods, currentPeriod, settings } = this.props;
    if ((!currentPeriod && projectPeriods) || prevProps.projectPeriods !== projectPeriods) {
      dispatchSetCurrentPeriod(projectPeriods.byAll.period);
    }

    if (
      settings &&
      !isEqual(
        get(prevProps, ['settings', 'globalSettings', 'dateRange']),
        get(this.props, ['settings', 'globalSettings', 'dateRange']),
      )
    ) {
      if (this.props.activeBenchmark) {
        this.instantiateRangeDate();
      }
    }
  }

  instantiateRangeDate() {
    const { currentPeriod, settings, dispatchSetRangeFilter } = this.props;
    const startDate =
      get(settings, ['globalSettings', 'dateRange', 'startDate']) || (currentPeriod && currentPeriod.startDate);
    const endDate =
      get(settings, ['globalSettings', 'dateRange', 'endDate']) || (currentPeriod && currentPeriod.endDate);
    dispatchSetRangeFilter(CONTEXT.GLOBAL, {
      startDate,
      endDate,
    });
  }

  dispatchRangeFilters(type, values) {
    const { columns, cards } = this.props.settings;

    columns.forEach((column, colIndex) => {
      const finalValidFullDateRange = isValidFullDateRange(values, column.settings.dateRange);
      this.props.dispatchSetRangeFilter(CONTEXT.COLUMN, column.settings.dateRange, finalValidFullDateRange, colIndex);
    });

    cards.forEach((card, cardIndex) => {
      const finalValidFullDateRange = isValidFullDateRange(values, card.settings.dateRange);
      this.props.dispatchSetRangeFilter(CONTEXT.CARD, card.settings.dateRange, finalValidFullDateRange, cardIndex);
    });
    this.props.dispatchSetRangeFilter(CONTEXT.GLOBAL, values);
  }

  render() {
    const { projectPeriods, settings } = this.props;
    const dateRange = get(settings, ['globalSettings', 'dateRange']);
    const { t } = this.props;
    const SHContent = (
      <>
        <Project />
        <ListTriggersContainer />
        {/* <DisplaySwitcher /> */}
      </>
    );
    // const collapseContent = (
    //   <Container>
    //     {dateRange && (
    //       <DatePickerContainer
    //         dateRange={dateRange}
    //         projectPeriods={projectPeriods}
    //         dispatcher={(type, values) => this.dispatchRangeFilters(type, values)}
    //         context={CONTEXT.GLOBAL}
    //         marginLeft="0"
    //         marginRight="3em"
    //         isInSubheader
    //       />
    //     )}
    //     <LatestBatchIndicator />
    //     <ContainInput>
    //       <p>{t('Filters')}</p>
    //       <StyledHeaderProjectFilters>
    //         <GlobalFiltersValueProvider>
    //           <BenchmarkHeaderProjectFilters />
    //         </GlobalFiltersValueProvider>
    //       </StyledHeaderProjectFilters>
    //     </ContainInput>
    //   </Container>
    // );

    return <SubHeader {...this.props} SHContent={SHContent} allowCollapse={false} />;
  }
}
const mapStateToProps = ({
  periods: { projectPeriods, currentPeriod },
  benchmark: { activeBenchmark },
  projects: { currentProject },
  filters: { filterKeys },
}) => ({
  projectPeriods,
  currentPeriod,
  currentProject,
  activeBenchmark,
  settings: get(activeBenchmark, ['settings']),
  range: get(filterKeys, ['range']),
});

const mapDispatchToProps = dispatch => ({
  dispatchSetCurrentPeriod: newState => {
    dispatch(setCurrentPeriod(newState));
  },
  dispatchSetRangeFilter: (type, values, mergedValues, itemIndex) => {
    dispatch(setRangeFilter(type, values, mergedValues, itemIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('reporting')(TriggersSubHeader));
