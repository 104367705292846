import React from 'react';
import styled from 'styled-components';
import CQuery from '_Container/QQuery/CQuery';
import querySchema from '_Cards/MentionsCards/MentionBadgeCard/graphql/getMentionBadgeCardData';
import { grayShades, themeColors } from 'styles/abstracts/colors';
import { spacing, ImageSize } from 'styles/abstracts/variables';
import EindexBadgeSection from '_Cards/EIndexCards/EindexBadgeCard/EindexBadgeSection';
import { getFontSizeStyles } from 'styles/abstracts/typography';
import SatisfactionNpsSection from '_Cards/SatisfactionCards/SatisfactionNpsCard/SatisfactionNpsSection';
import EmotionBreakdownDonutSection from '_Cards/EmotionsCards/EmotionBreakdownDonutCard/EmotionBreakdownDonutSection';
import CategoryBreakdownSection from '_Cards/ThematicsCard/ThematicsOverviewCards/CategoryBreakdownCard/CategoryBreakdownSection/CategoryBreakdownSection.tsx';
import ThematicsHorizontalSection from '_Cards/ThematicsCard/ThematicsOverviewCards/ThematicsHorizontalChartCard/ThematicsHorizontalSection/ThematicsHorizontalSection';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Icon from '_Components/Icons/Icon';
import { COLUMN_WIDTH } from '../../configs/columnsConfig';
import MentionText from './MentionText';
import DateText from './DateText';

const NoDataMessage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    color: ${grayShades.g800};
    background-color: ${grayShades.g200};
    border-radius: 50%;
    width: 6em;
    height: 6em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Graphs = styled.div`
  width: ${COLUMN_WIDTH}px;
  text-align: center;
  border-right: 1px solid ${grayShades.g300};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.medium};
  min-height: ${props => props.biggerCard && '15em'};
  height: ${props => (props.biggerCard ? `${props.biggerCard}em` : 'auto')};
`;

const SubTitle = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${themeColors.secondary} ${getFontSizeStyles(14)};
`;

const placeholderComponents = {
  noDataComponent: (
    <NoDataMessage s={props => props.data}>
      <div>NO DATA</div>
    </NoDataMessage>
  ),
  errorComponent: (
    <NoDataMessage>
      <div>ERROR</div>
    </NoDataMessage>
  ),
};

const StyledSatisfactionNpsSection = styled(SatisfactionNpsSection)`
  height: 15rem !important;
`;

const CardHeader = styled.div`
  flex-grow: 1;
  width: 100%;
  position: relative;
`;

const Title = styled.p`
  word-break: break-word;
`;

const StyledCardHeaderIcon = styled(Icon)`
  position: absolute;
  right: 5px;
`;

const MentionInfos = ({ filters }) => (
  <CQuery filters={filters} query={querySchema} errorComponent={<></>} noDataComponent={<></>} loadingComponent={<></>}>
    <MentionText filters={filters} />
  </CQuery>
);

const mapStateToProps = (state: any) => ({
  benchmarkHorizontalLevelToDisplay: get(state, ['benchmark', 'horizontalLevelToDisplay']),
  benchmarkAspectToDisplay: get(state, ['benchmark', 'aspectToDisplay']),
  benchmarkUnitToDisplay: get(state, ['benchmark', 'unitToDisplay']),
  benchmarkHorizontalCategories: get(state, ['benchmark', 'horizontalCategories']),
  benchmarkHorizontalCategoriesOrder: get(state, ['benchmark', 'horizontalCategoriesOrder']),
});

const GraphsComponent = ({
  children,
  title,
  filters,
  displayBenchmarkDetails,
  biggerCard,
  condensedSubTitle,
  index,
  className,
  benchmarkHorizontalCategoriesOrder,
}) => {
  let icon = '';
  if (benchmarkHorizontalCategoriesOrder && benchmarkHorizontalCategoriesOrder.id) {
    switch (benchmarkHorizontalCategoriesOrder.id.split('_')[1]) {
      case 'asc':
        icon = 'INTERFACE_SORT_ASC';
        break;
      case 'desc':
        icon = 'INTERFACE_SORT_DESC';
        break;
      default:
        icon = '';
        break;
    }
  }
  return (
    <Graphs biggerCard={biggerCard}>
      <CardHeader>
        <Title>
          {title}
          {index === 0 && className === 'thematicsHorizontal' && (
            <StyledCardHeaderIcon icon={icon} size={ImageSize.medium} />
          )}
        </Title>
        <MentionInfos filters={filters} />
        {displayBenchmarkDetails && (
          <>
            <DateText filters={filters} />
            <SubTitle>
              {filters.criteriaKeys.map(criteriaKey => `${criteriaKey.name} : ${criteriaKey.values.join(', ')}; `)}
            </SubTitle>
            <SubTitle>
              {filters.thematics
                .map(thematic => {
                  const { joinSpecify, ...obj } = thematic
                  let thematicTitle =  [
                    Object.keys(obj)
                      .map(key => thematic[key])
                      .join(' > '),
                  ]
                  if (joinSpecify) thematicTitle = `NOT ${thematicTitle}`
                  return thematicTitle;
                })
                .join(', ')}
            </SubTitle>
          </>
        )}
        {!condensedSubTitle && <br />}
      </CardHeader>
      {children}
    </Graphs>
  );
};

const ConnectedGraphsComponent = connect(mapStateToProps)(GraphsComponent);

const benchmarkCards = {
  eindex: (title, filters, color, mergeDataBeforeGetComponent, displayBenchmarkDetails, columns, column, index) => (
    <GraphsComponent title={title} filters={filters} displayBenchmarkDetails={displayBenchmarkDetails}>
      <EindexBadgeSection filters={filters} {...placeholderComponents} title={title} index={index} />
    </GraphsComponent>
  ),
  sat_nps: (title, filters, color, mergeDataBeforeGetComponent, displayBenchmarkDetails) => (
    <GraphsComponent title={title} filters={filters} displayBenchmarkDetails={displayBenchmarkDetails}>
      <StyledSatisfactionNpsSection filters={filters} {...placeholderComponents} />
    </GraphsComponent>
  ),
  emotion_breakdown: (title, filters, color, mergeDataBeforeGetComponent, displayBenchmarkDetails) => (
    <GraphsComponent title={title} filters={filters} displayBenchmarkDetails={displayBenchmarkDetails} biggerCard={22}>
      <EmotionBreakdownDonutSection
        filters={filters}
        title={title}
        richText={{
          rich: {
            percent: {
              fontSize: 14,
              lineHeight: 2,
            },
            legend: {
              fontSize: 14,
              fontWeight: 'bold',
            },
          },
        }}
        {...placeholderComponents}
        benchmarkMode
      />
    </GraphsComponent>
  ),
  category_breakdown: (
    title,
    filters,
    color,
    mergeDataBeforeGetComponent,
    displayBenchmarkDetails,
    columns,
    column,
  ) => (
    <GraphsComponent
      title={title}
      filters={filters}
      displayBenchmarkDetails={displayBenchmarkDetails}
      biggerCard={22}
      className="categoryBreakdown"
    >
      <CategoryBreakdownSection
        title={title}
        filters={filters}
        columns={columns}
        column={column}
        {...placeholderComponents}
      />
    </GraphsComponent>
  ),
  thematics_horizontal: (
    title,
    filters,
    color,
    mergeDataBeforeGetComponent,
    displayBenchmarkDetails,
    columns,
    column,
    index,
  ) => (
    <ConnectedGraphsComponent
      title={title}
      filters={filters}
      displayBenchmarkDetails={displayBenchmarkDetails}
      biggerCard={28}
      className="thematicsHorizontal"
      index={index}
      condensedSubTitle
    >
      <ThematicsHorizontalSection
        title={title}
        filters={filters}
        columns={columns}
        column={column}
        index={index}
        {...placeholderComponents}
      />
    </ConnectedGraphsComponent>
  ),
};

export default benchmarkCards;
