import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, last, findIndex, find, get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { setActiveBenchmark } from '_Resources/Benchmark/Actions/setActiveBenchmark';
import QButton from '_Components/Buttons/QButton/QButton';
import BenchmarkDropdown from './_Containers/BenchmarkDropdown/BenchmarkDropdown';
import InputNameModal from './_Containers/InputNameModal/InputNameModal';
import ManageBenchmarksModal from './_Containers/ManageBenchmarksModal/ManageBenchmarksModal';

class ListBenchmark extends React.Component {
  static propTypes = {
    benchmarkList: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string,
          isDefault: PropTypes.bool.isRequired,
          settings: PropTypes.array,
        }),
      ).isRequired,
    ).isRequired,
    t: PropTypes.func.isRequired,
    dispatchSetActiveBenchmark: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    activeBenchmarkId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isAddBenchmarkModalOpen: false,
      isManageBenchmarksModalOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleAddBenchmarkModal = this.toggleAddBenchmarkModal.bind(this);
    this.toggleManageBenchmarksModal = this.toggleManageBenchmarksModal.bind(this);
  }

  toggleDropdown() {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  }

  toggleAddBenchmarkModal() {
    this.setState({
      isAddBenchmarkModalOpen: !this.state.isAddBenchmarkModalOpen,
    });
  }

  toggleManageBenchmarksModal() {
    this.setState({
      isManageBenchmarksModalOpen: !this.state.isManageBenchmarksModalOpen,
    });
  }

  componentDidMount() {
    if (this.props.location?.state?.fromLandingPage) {
      this.setState({ isAddBenchmarkModalOpen: true });
    }
    if (this.props.benchmarkList && !isEmpty(this.props.benchmarkList)) {
      this.props.dispatchSetActiveBenchmark(last(this.props.benchmarkList));
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(this.props.benchmarkList)) {
      if (prevProps.benchmarkList !== this.props.benchmarkList) {
        if (this.props.projectId !== this.state.projectId) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ projectId: this.props.projectId });
          this.loadDefaultOrLastBenchmark([]);
        } else if (prevProps.benchmarkList.length < this.props.benchmarkList.length) {
          this.props.dispatchSetActiveBenchmark(last(this.props.benchmarkList));
        } else if (prevProps.benchmarkList.length > this.props.benchmarkList.length || !prevProps.benchmarkList) {
          if (!isEmpty(this.props.benchmarkList)) {
            this.loadDefaultOrLastBenchmark(prevProps);
          }
        }
      }
    } else {
      this.props.dispatchSetActiveBenchmark(undefined);
    }
  }

  loadDefaultOrLastBenchmark = prevProps => {
    const defaultBenchmarkIndex = findIndex(this.props.benchmarkList, ['isDefault', true]);
    if (defaultBenchmarkIndex > -1) {
      if (
        (prevProps.benchmarkList && prevProps.benchmarkList.length === this.props.benchmarkList.length) ||
        !prevProps.benchmarkList ||
        prevProps.projectId !== this.props.projectId
      ) {
        this.props.dispatchSetActiveBenchmark(this.props.benchmarkList[defaultBenchmarkIndex]);
      } else {
        const { activeBenchmarkId } = this.props;
        const isActiveDeleted = !find(this.props.benchmarkList, benchmark => benchmark.id === activeBenchmarkId);
        if (isActiveDeleted) {
          this.props.dispatchSetActiveBenchmark(this.props.benchmarkList[defaultBenchmarkIndex]);
        }
      }
    } else {
      this.props.dispatchSetActiveBenchmark(last(this.props.benchmarkList));
    }
  };

  render() {
    const { benchmarkList, t } = this.props;
    return (
      <>
        {/* <QueryBenchmarkList /> */}
        <InputNameModal
          title={t('Add a new benchmark')}
          subtitle={t('Easy peasy... Just name it and describe it !')}
          isOpen={this.state.isAddBenchmarkModalOpen}
          toggle={this.toggleAddBenchmarkModal}
        />
        <ManageBenchmarksModal
          isOpen={this.state.isManageBenchmarksModalOpen}
          toggle={this.toggleManageBenchmarksModal}
          toggleAddBenchmarkModal={this.toggleAddBenchmarkModal}
          benchmarkList={benchmarkList}
          canCreateBenchmark={this.props.canCreateBenchmark}
        />

        {// eslint-disable-next-line no-nested-ternary
        !isEmpty(benchmarkList) ? (
          <>
            <BenchmarkDropdown
              isOpen={this.state.isDropdownOpen}
              toggle={this.toggleDropdown}
              toggleAddBenchmarkModal={this.toggleAddBenchmarkModal}
              toggleManageBenchmarksModal={this.toggleManageBenchmarksModal}
              benchmarkList={benchmarkList}
              dispatchSetActiveBenchmark={this.props.dispatchSetActiveBenchmark}
            />
          </>
        ) : this.props.benchmarkList ? (
          <QButton
            onClick={this.toggleAddBenchmarkModal}
            disabled={!this.props.canCreateBenchmark}
            id="listBenchmarkButton"
            popoverText={
              !this.props.canCreateBenchmark &&
              t("header:You can't create more benchmarks. Please upgrade your plan in order to create more benchmarks.")
            }
          >
            Create a benchmark
          </QButton>
        ) : (
          <QButton onClick={this.toggleAddBenchmarkModal} disabled>
            Loading...
          </QButton>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    benchmarkList: state.benchmark.projectBenchmarks,
    projectId: get(state, ['projects', 'currentProject', 'id']),
    activeBenchmarkId: get(state, ['benchmark', 'activeBenchmark', 'id']),
    canCreateBenchmark: get(state, ['auth', 'user', 'account', 'canCreateBenchmark']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveBenchmark: newState => {
      dispatch(setActiveBenchmark(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('Benchmark', 'header')(withRouter(ListBenchmark)));
