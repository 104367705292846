import React from 'react';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Icon from '_Components/Icons/Icon';
import QButton from '_Components/Buttons/QButton/QButton';
import { setGraphThematics } from '_Resources/Benchmark/Actions/setGraphThematics';
import { setActiveTriggersTable } from '_Resources/Triggers/Actions/setActiveTriggersTable';
import { grayShades, lightBlue, purple, opacity, white } from '../../../../../../../../../styles/abstracts/colors';
import { spacing } from '../../../../../../../../../styles/abstracts/variables';
import { fontSizes } from '../../../../../../../../../styles/abstracts/typography';

const ToggleButton = styled(QButton)`
  display: flex !important;
  justify-content: space-between;
  color: ${purple} !important;
  min-width: 200px;
  text-align: left !important;
  position: relative;
`;

const DropdownSection = styled.div`
  max-height: 15em;
  overflow-y: scroll;
`;

const TriggerTableItem = styled(DropdownItem)`
  font-size: ${fontSizes.smaller};
  font-weight: bold !important;
  color: #2e096b !important;
  padding: ${spacing.mediumSmall} !important;
`;

const TriggersTablesOptions = styled(TriggerTableItem)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const AddTriggersTableItem = styled(TriggersTablesOptions)`
  color: ${props => (props.disabled ? opacity.lightBlue : lightBlue)} !important;
  cursor: pointer;
`;

const CustomDivider = styled(DropdownItem)`
  width: 75%;
  margin: ${spacing.small} auto !important;
`;

const StyledMenu = styled(DropdownMenu)`
  top: 0 !important;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  top: calc(${props => props.menuDimensions?.top}px - 1em) !important;
  left: calc(${props => props.menuDimensions?.right}px + 3em) !important;

  & .arrow:after {
    border-right-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class TriggersTablesDropdown extends React.Component {
  static propTypes = {
    triggersTableList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        settings: PropTypes.array,
      }),
    ).isRequired,
    isOpen: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    toggleAddTriggersTableModal: PropTypes.func.isRequired,
    toggleManageTriggersTablesModal: PropTypes.func.isRequired,
    dispatchSetActiveTriggersTable: PropTypes.func.isRequired,
    dispatchSetGraphThematics: PropTypes.func,
    activeTriggersTableId: PropTypes.number.isRequired,
    activeTriggersTableTitle: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activeTriggersTableTitle: 'No triggers table',
    dispatchSetGraphThematics: () => {},
  };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      isPopoverOpen: false,
    };
  }

  getDropdownMenuDimensions() {
    return {
      top: menu.offsetTop,
      right: menu.offsetLeft,
    };
  }

  componentDidUpdate(prevProps: { isOpen: any }, prevState: any) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      this.setState({ menuDimensions: this.getDropdownMenuDimensions() });
    }
  }

  render() {
    const {
      triggersTableList,
      t,
      activeTriggersTableId,
      dispatchSetActiveTriggersTable,
      dispatchSetGraphThematics,
      activeTriggersTableTitle,
      isOpen,
      toggle,
      toggleAddTriggersTableModal,
      canCreateTriggersTable,
      toggleManageTriggersTablesModal,
    } = this.props;
    const { menuDimensions } = this.state;
    return (
      <Dropdown isOpen={isOpen} toggle={toggle} id="addTriggersTable">
        <DropdownToggle onClick={toggle} tag="span">
          <ToggleButton bgColor="grayShades.g300" icon="INTERFACE_CHEVRON_DOWN" iconColor="purple" disableHover>
            {t(activeTriggersTableTitle)}
          </ToggleButton>
        </DropdownToggle>
        <StyledMenu positionFixed>
          {!isEmpty(triggersTableList) && (
            <>
              <DropdownSection>
                {triggersTableList.map((data: { id: any; title: any }) => (
                  <TriggerTableItem
                    onClick={() => {
                      dispatchSetActiveTriggersTable(data);
                      dispatchSetGraphThematics([]);
                    }}
                    active={data.id === activeTriggersTableId}
                  >
                    {data.title}
                  </TriggerTableItem>
                ))}
              </DropdownSection>
              <DropdownItem divider disabled />
            </>
          )}
          <AddTriggersTableItem onClick={toggleAddTriggersTableModal} disabled={!canCreateTriggersTable}>
            <div>
              <Icon icon="PLUS" size={24} color={canCreateTriggersTable ? lightBlue : opacity.lightBlue} />
              {t('Add a new triggers table')}
            </div>
          </AddTriggersTableItem>
          {!canCreateTriggersTable && isOpen && document.getElementById('addTriggersTable') && (
            <CustomPopover target="addTriggersTable" placement="right" isOpen menuDimensions={menuDimensions}>
              <CustomPopoverBody>
                {t(
                  "header:You can't create more benchmarks. Please upgrade your plan in order to create more benchmarks.",
                )}
              </CustomPopoverBody>
            </CustomPopover>
          )}
          <CustomDivider divider disabled />
          <TriggersTablesOptions onClick={toggleManageTriggersTablesModal}>
            <Icon icon="INTERFACE_GEAR" color={grayShades.g800} size={24} /> {t('Manage triggers tables')}
          </TriggersTablesOptions>
        </StyledMenu>
      </Dropdown>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    activeTriggersTableId: get(state, ['triggers', 'activeTriggersTable', 'id']),
    activeTriggersTableTitle: get(state, ['triggers', 'activeTriggersTable', 'title']),
    canCreateTriggersTable: get(state, ['auth', 'user', 'account', 'canCreateTriggersTable']) || true,
  };
}

function mapDispatchToProps(dispatch: (arg0: { type: string; data?: any; activeTriggersTable?: any }) => void) {
  return {
    dispatchSetGraphThematics: (newState: any) => {
      dispatch(setGraphThematics(newState));
    },
    dispatchSetActiveTriggersTable: (newState: any) => {
      dispatch(setActiveTriggersTable(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'header')(TriggersTablesDropdown));
