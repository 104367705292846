import produce from 'immer';
import {
  CONTEXT,
  TEMPORARY_STORE_TO_WATCH,
  FILTER_DIMENSIONS,
} from 'Routes/Insights/Triggers/_Components/configs/globalConfig';
import { FILTERS_REMOVE_STRATEGIES } from '_Utils/filters/filterUtils';
import { rawFilterValues } from '_Utils/filters/rawFilterValues';
import { uniqBy, cloneDeep } from 'lodash';
import { mergeThematics } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/ThematicsUtils';
import { isValidCriteriaKeys } from 'Routes/Benchmark/BenchmarkBoard/_Components/Modals/ModalPages/_Components/FiltersSwitcher/FiltersUtils/CriteriaKeysUtils';

const mergeFiltersArray = (initialSettings, filterType, filterValues, globalFilters) => {
  // const existingFilters = initialSettings.settings.filters[filterType] || [];
  const existingFilters = initialSettings[filterType] || [];

  const mergedFilters = [...existingFilters, ...rawFilterValues(filterValues)];
  const validMergedFilters =
    filterType === FILTER_DIMENSIONS.NORMAL
      ? isValidCriteriaKeys(mergedFilters, globalFilters)
      : mergeThematics(mergedFilters, globalFilters);
  return produce(initialSettings, newSettings => {
    // newSettings.settings.filters[filterType] = mergedFilters;
    newSettings[filterType] = mergedFilters;
    // newSettings.mergedSettings.filters[filterType] = validMergedFilters && uniqBy(validMergedFilters, v => v.option.id);
    newSettings[filterType] = validMergedFilters && uniqBy(validMergedFilters, v => v.option.id);
  });
};

export const addTriggersFilters = (state, { context, filterType, filterValues }) => {
  const currentFilters = cloneDeep(state[filterType]);

  const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField; // 'temporaryTriggers'

  const currentSettings = state[storeToWatch].settings; // 'temporaryTriggers.settings'

  const newSettings = mergeFiltersArray(currentSettings, filterType, filterValues, currentFilters);
  return produce(state, newState => {
    newState[storeToWatch].settings = newSettings;
  });
};

const removeFiltersFromArray = (
  initialSettings,
  { filterType, filterValues: [[filterValue], parentKey] },
  globalSettings,
) => {







  // console.log("initialSettings:", initialSettings); // ok triggers.temporaryTriggers.settings
  // console.log("filterType:", filterType); // 'thematics'
  // console.log("filterValues: {[[filterValue], parentKey]}:", {filterValues: [[filterValue], parentKey]});
  // console.log("globalSettings:", globalSettings);


  const removingStrategy = FILTERS_REMOVE_STRATEGIES[filterType] || FILTERS_REMOVE_STRATEGIES.default;

  // console.log("removingStrategy:", removingStrategy);


  const existingFilters = initialSettings[filterType] || [];

  // console.log("existingFilters:", existingFilters);


  const removedFiltersArray = removingStrategy(existingFilters, filterValue, parentKey);
  return produce(initialSettings, newSettings => {
    newSettings[filterType] = removedFiltersArray;
    newSettings[filterType] =
      removedFiltersArray.length > 0
        ? isValidCriteriaKeys(removedFiltersArray, globalSettings)
        : mergeThematics(removedFiltersArray, globalSettings);
  });
};

export const removeTriggersFilters = (state, { context, filterType, filterValues }) => {

  // console.log("state:", state);

  // switch (context) {
    //   case CONTEXT.GLOBAL:
    //     // TODO : implement global filters and thematics
    //     return { ...state };
    //   case CONTEXT.COLUMN:
    //   case CONTEXT.CARD: {

    const storeToWatch = TEMPORARY_STORE_TO_WATCH[context].temporarySettingsField; // 'temporaryTriggers'

    // console.log("storeToWatch:", storeToWatch); // 'temporaryTriggers'

    const currentSettings = state[storeToWatch].settings; // ok triggers.temporaryTriggers.settings

    console.log("currentSettings:", currentSettings);
    console.log("filterType:", filterType); // 'thematics'
    console.log("filterValues:", filterValues); // object
    console.log("state.temporaryTriggers.settings[filterType]:", state.temporaryTriggers.settings[filterType]); // object

  const newSettings = removeFiltersFromArray(
    currentSettings,
    {
      filterType,
      filterValues,
    },
    state.temporaryTriggers.settings[filterType],
  );
  console.log("newSettings:", newSettings);
  return produce(state, newState => {


    newState[storeToWatch].settings = newSettings;
  });
  // }
  //   default:
  //     return { ...state };
  // }
};

export const updateFilters = (state, { filters }) => {
  if (!filters) return { ...state };
  const normal = filters.normal && rawFilterValues(filters.normal);
  const related = filters.related && rawFilterValues(filters.related);
  const thematic = filters.thematic && rawFilterValues(filters.thematic);
  const keywords = filters.keywords && rawFilterValues(filters.keywords);
  const cardsStore =
    state.activeBenchmark && state.activeBenchmark.settings && cloneDeep(state.activeBenchmark.settings.cards);
  const columnsStore =
    state.activeBenchmark && state.activeBenchmark.settings && cloneDeep(state.activeBenchmark.settings.columns);
  return produce(state, newState => {
    if (newState.activeBenchmark) {
      newState.activeBenchmark.settings.globalSettings.filters.normal = normal;
      newState.activeBenchmark.settings.globalSettings.filters.thematic = thematic;
      newState.activeBenchmark.settings.globalSettings.filters.related = related;
      newState.activeBenchmark.settings.globalSettings.filters.keywords = keywords;
    }
    const mergedThematics = item =>
      mergeThematics(item.settings.filters.thematic, newState.activeBenchmark.settings.globalSettings.filters.thematic);
    cardsStore &&
      cardsStore.forEach((card, index) => {
        newState.activeBenchmark.settings.cards[index].mergedSettings.filters.thematic = mergedThematics(card);
        newState.activeBenchmark.settings.cards[index].mergedSettings.filters.normal = isValidCriteriaKeys(
          card.settings.filters.normal,
          normal,
        );
      });
    columnsStore &&
      columnsStore.forEach((column, index) => {
        newState.activeBenchmark.settings.columns[index].mergedSettings.filters.thematic = mergedThematics(column);
        newState.activeBenchmark.settings.columns[index].mergedSettings.filters.normal = isValidCriteriaKeys(
          column.settings.filters.normal,
          normal,
        );
      });
  });
};
