import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, last, findIndex, find, get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { setActiveTriggersTable } from '_Resources/Triggers/Actions/setActiveTriggersTable';
import QButton from '_Components/Buttons/QButton/QButton';
import QueryEmotionalTriggersTableList from '_Resources/Triggers/Services/QueryEmotionalTriggersTableList';
import TriggersTablesDropdown from './_Containers/TriggersTablesDropdown/TriggersTablesDropdown';
import InputNameModal from './_Containers/InputNameModal/InputNameModal';
import ManageTriggersModal from './_Containers/ManageTriggersModal/ManageTriggersModal';

class ListTriggersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isAddTriggersTableModalOpen: false,
      isManageTriggersTablesModalOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleAddTriggersTableModal = this.toggleAddTriggersTableModal.bind(this);
    this.toggleManageTriggersTablesModal = this.toggleManageTriggersTablesModal.bind(this);
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({ isDropdownOpen: !isDropdownOpen });
  }

  toggleAddTriggersTableModal() {
    const { isAddTriggersTableModalOpen } = this.state;
    this.setState({
      isAddTriggersTableModalOpen: !isAddTriggersTableModalOpen,
    });
  }

  toggleManageTriggersTablesModal() {
    const { isManageTriggersTablesModalOpen } = this.state;

    this.setState({
      isManageTriggersTablesModalOpen: !isManageTriggersTablesModalOpen,
    });
  }

  componentDidMount() {
    const { location, triggersTableList, dispatchSetActiveTriggersTable } = this.props;

    if (location?.state?.fromLandingPage) {
      this.setState({ isAddTriggersTableModalOpen: true });
    }
    if (triggersTableList && !isEmpty(triggersTableList)) {
      dispatchSetActiveTriggersTable(last(triggersTableList));
    }
  }

  componentDidUpdate(prevProps) {
    const { triggersTableList, dispatchSetActiveTriggersTable, projectId } = this.props;
    const { projectId: stateProjectId } = this.state;
    if (!isEmpty(triggersTableList)) {
      if (prevProps.triggersTableList !== triggersTableList) {
        if (projectId !== stateProjectId) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ projectId });
          this.loadDefaultOrLastTriggersTable([]);
        } else if (prevProps.triggersTableList.length < triggersTableList.length) {
          dispatchSetActiveTriggersTable(last(triggersTableList));
        } else if (prevProps.triggersTableList.length > triggersTableList.length || !prevProps.triggersTableList) {
          if (!isEmpty(triggersTableList)) {
            this.loadDefaultOrLastTriggersTable(prevProps);
          }
        }
      }
    } else {
      dispatchSetActiveTriggersTable(undefined);
    }
  }

  loadDefaultOrLastTriggersTable = prevProps => {
    const { triggersTableList, dispatchSetActiveTriggersTable, projectId, activeTriggersTableId } = this.props;
    const defaultTriggersTableIndex = findIndex(triggersTableList, ['isDefault', true]);
    if (defaultTriggersTableIndex > -1) {
      if (
        (prevProps.triggersTableList && prevProps.triggersTableList.length === triggersTableList.length) ||
        !prevProps.triggersTableList ||
        prevProps.projectId !== projectId
      ) {
        dispatchSetActiveTriggersTable(triggersTableList[defaultTriggersTableIndex]);
      } else {
        const isActiveDeleted = !find(triggersTableList, triggersTable => triggersTable.id === activeTriggersTableId);
        if (isActiveDeleted) {
          dispatchSetActiveTriggersTable(triggersTableList[defaultTriggersTableIndex]);
        }
      }
    } else {
      dispatchSetActiveTriggersTable(last(triggersTableList));
    }
  };

  render() {
    const { triggersTableList, t, dispatchSetActiveTriggersTable, canCreateTriggersTable } = this.props;
    const { isAddTriggersTableModalOpen, isManageTriggersTablesModalOpen, isDropdownOpen } = this.state;

    return (
      <>
        <QueryEmotionalTriggersTableList />
        <InputNameModal
          title={t('Add a new triggers table')}
          subtitle={t('Easy peasy... Just name it and describe it !')}
          isOpen={isAddTriggersTableModalOpen}
          toggle={this.toggleAddTriggersTableModal}
        />
        <ManageTriggersModal
          isOpen={isManageTriggersTablesModalOpen}
          toggle={this.toggleManageTriggersTablesModal}
          toggleAddTriggersTableModal={this.toggleAddTriggersTableModal}
          triggersTableList={triggersTableList}
          canCreateTriggersTable={canCreateTriggersTable}
        />

        {// eslint-disable-next-line no-nested-ternary
        !isEmpty(triggersTableList) ? (
          <>
            <TriggersTablesDropdown
              isOpen={isDropdownOpen}
              toggle={this.toggleDropdown}
              toggleAddTriggersTableModal={this.toggleAddTriggersTableModal}
              toggleManageTriggersTablesModal={this.toggleManageTriggersTablesModal}
              triggersTableList={triggersTableList}
              dispatchSetActiveTriggersTable={dispatchSetActiveTriggersTable}
            />
          </>
        ) : triggersTableList ? (
          <QButton
            onClick={this.toggleAddTriggersTableModal}
            disabled={!canCreateTriggersTable}
            id="listTriggersTablesButton"
            popoverText={
              !canCreateTriggersTable &&
              t(
                "header:You can't create more triggers tables. Please upgrade your plan in order to create more triggers tables.",
              )
            }
          >
            Create a triggers table
          </QButton>
        ) : (
          <QButton onClick={this.toggleAddTriggersTableModal} disabled>
            {t('card:Loading')}
            {' ...'}
          </QButton>
        )}
      </>
    );
  }
}

ListTriggersTable.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      fromLandingPage: PropTypes.bool,
    }),
  }),
  triggersTableList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      isDefault: PropTypes.bool.isRequired,
      createdBy: PropTypes.shape({
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
      settings: PropTypes.shape({
        analysisAxis: PropTypes.string.isRequired,
        calculationCriteria: PropTypes.string.isRequired,
        itemsToDisplay: PropTypes.shape({
          name: PropTypes.string.isRequired,
          key: PropTypes.string,
        }).isRequired,
        significanceThreshold: PropTypes.number.isRequired,
        range: PropTypes.shape({
          startDate: PropTypes.string.isRequired,
          endDate: PropTypes.string.isRequired,
        }).isRequired,
        thematics: PropTypes.arrayOf(
          PropTypes.shape({
            thematic: PropTypes.string.isRequired,
            subThematic: PropTypes.string,
            subSubThematic: PropTypes.string,
          }),
        ).isRequired,
        criteriaKeys: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            values: PropTypes.arrayOf(PropTypes.string).isRequired,
          }),
        ).isRequired,
      }).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          originalColor: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          originalName: PropTypes.string.isRequired,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              originalName: PropTypes.string.isRequired,
              percentage: PropTypes.number.isRequired,
              value: PropTypes.number.isRequired,
            }),
          ).isRequired,
          value: PropTypes.number.isRequired,
          deleted: PropTypes.bool.isRequired,
          masked: PropTypes.bool.isRequired,
        }),
      ),
      customizations: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string.isRequired,
          originalColor: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          originalName: PropTypes.string.isRequired,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              originalName: PropTypes.string.isRequired,
            }),
          ).isRequired,
          value: PropTypes.number.isRequired,
          deleted: PropTypes.bool.isRequired,
          masked: PropTypes.bool.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
  t: PropTypes.func.isRequired,
  dispatchSetActiveTriggersTable: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  activeTriggersTableId: PropTypes.number.isRequired,
  canCreateTriggersTable: PropTypes.bool,
};

ListTriggersTable.defaultProps = {
  canCreateTriggersTable: true,
  location: {
    hash: '',
    key: '',
    pathname: '',
    search: '',
    state: {
      fromLandingPage: false,
    },
  },
};

function mapStateToProps(state) {
  return {
    triggersTableList: state.triggers.projectTriggersTables || [],
    projectId: get(state, ['projects', 'currentProject', 'id']),
    activeTriggersTableId: get(state, ['triggers', 'activeTriggersTable', 'id']),
    canCreateTriggersTable: get(state, ['auth', 'user', 'account', 'canCreateTriggersTable']) || true,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveTriggersTable: newState => {
      dispatch(setActiveTriggersTable(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'header', 'card')(withRouter(ListTriggersTable)));
