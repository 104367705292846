import { SELECTIONS, RECOMMENDATIONS } from 'constants/index.js';
import { grayShades, purple } from 'styles/abstracts/colors';

const TRIGGERS_EMOTIONS = {
  happiness: {
    color: '#DB2156',
    icon: 'EMOTION_HAPPINESS',
  },
  surprise: {
    color: '#FF8104',
    icon: 'EMOTION_SURPRISE',
  },
  calm: {
    color: '#F5D800',
    icon: 'EMOTION_CALM',
  },
  fear: {
    color: '#8BDB00',
    icon: 'EMOTION_FEAR',
  },
  sadness: {
    color: '#0EC3E3',
    icon: 'EMOTION_SADNESS',
  },
  anger: {
    color: '#007AFF',
    icon: 'EMOTION_ANGER',
  },
  disgust: {
    color: '#222C66',
    icon: 'EMOTION_DISGUST',
  },
};

const TRIGGERS_SENTIMENTS = {
  positive: {
    color: '#8bdb00',
    icon: 'SENTIMENT_POSITIVE',
  },
  neutral: {
    color: '#c3c8d9',
    icon: 'SENTIMENT_NEUTRAL',
  },
  negative: {
    color: '#000000',
    icon: 'SENTIMENT_NEGATIVE',
  },
};

export const allAxisColors = {
  ...TRIGGERS_SENTIMENTS,
  ...TRIGGERS_EMOTIONS,
  ...SELECTIONS,
  ...RECOMMENDATIONS,
};

export const addOriginalColorsInData = data => {
  if (data?.length > 0) {
    const dataWithOriginalColors = data.map(dataItem => {
      if (dataItem?.originalColor === null) {
        if (allAxisColors[dataItem.name]?.color !== undefined) {
          dataItem.originalColor = allAxisColors[dataItem.name].color;
          dataItem.color = allAxisColors[dataItem.name].color;
          return dataItem;
        }
        dataItem.originalColor = purple;
        dataItem.color = purple;
      }
      return dataItem;
    });
    return dataWithOriginalColors;
  }
  return data;
};
