import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { setActiveTriggersTable } from '_Resources/Triggers/Actions/setActiveTriggersTable';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Popover, PopoverBody } from 'reactstrap';
import Icon from '_Components/Icons/Icon';
import MutationDeletedEmotionalTriggerTable from '_Resources/Triggers/Services/MutationDeleteEmotionalTriggerTable';
import MutationDuplicateTriggersTable from '_Resources/Triggers/Services/MutationDuplicateTriggersTable';
import { ImageSize, spacing } from '../../../../../../../../../../../../styles/abstracts/variables';
import { grayShades, red, white } from '../../../../../../../../../../../../styles/abstracts/colors';
import { fontSizes } from '../../../../../../../../../../../../styles/abstracts/typography';
import InputNameModal from '../../../../InputNameModal/InputNameModal';

const Options = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  cursor: pointer;
`;

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.delete ? red : `#2e096b`)} !important;
  padding: ${spacing.medium} !important;
  font-size: ${fontSizes.smaller};
  cursor: pointer;
`;

const Menu = styled(DropdownMenu)`
  margin-top: 5em !important;
`;

const CustomPopover = styled(Popover)`
  background-color: black !important;
  top: calc(${props => props.menuDimensions?.top}px - 1em) !important;
  left: calc(${props => props.menuDimensions?.right}px + 5em) !important;

  & .arrow:after {
    border-right-color: black !important;
  }
`;

const CustomPopoverBody = styled(PopoverBody)`
  color: ${white} !important;
`;

class TriggersTableOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isRenameModalOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleRenameModal = this.toggleRenameModal.bind(this);
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({ isDropdownOpen: !isDropdownOpen });
  }

  setActiveTriggers() {
    const { triggersTable, dispatchSetActiveTriggersTable, toggleModal } = this.props;
    dispatchSetActiveTriggersTable(triggersTable);
    toggleModal();
  }

  toggleRenameModal() {
    const { isRenameModalOpen } = this.state;
    this.setState({ isRenameModalOpen: !isRenameModalOpen });
  }

  getDropdownMenuDimensions() {
    const menu = document.getElementById('triggersTableOptionsDropdown');

    return {
      top: menu.offsetTop + menu.offsetHeight * 4,
      right: menu.getBoundingClientRect().width * 5,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { isDropdownOpen } = this.state;
    if (isDropdownOpen !== prevState.isDropdownOpen) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ menuDimensions: this.getDropdownMenuDimensions() });
    }
  }

  render() {
    const { t, triggersTable, canCreateTriggersTable } = this.props;
    const { isDropdownOpen, menuDimensions, isRenameModalOpen } = this.state;
    const { id: triggersTableId } = triggersTable;

    return (
      <>
        <Options>
          <Dropdown
            isOpen={isDropdownOpen}
            toggle={this.toggleDropdown}
            direction="right"
            id="triggersTableOptionsDropdown"
          >
            <StyledDropdownToggle tag="span">
              <Icon icon="INTERFACE_DOTS_VERTICAL" size={ImageSize.medium} color={grayShades.g800} />
            </StyledDropdownToggle>
            <Menu>
              <OptionsItem onClick={() => this.setActiveTriggers()}>
                <Icon icon="CHECK_ALL" color={grayShades.g800} size={ImageSize.medium} />
                &nbsp; {t('See this triggers table')}
              </OptionsItem>
              <OptionsItem onClick={this.toggleRenameModal}>
                <Icon icon="INTERFACE_EDIT" color={grayShades.g800} size={ImageSize.medium} />
                &nbsp; {t('Rename')}
              </OptionsItem>
              <MutationDuplicateTriggersTable triggersTableId={triggersTableId} />
              <DropdownItem divider />
              <MutationDeletedEmotionalTriggerTable triggersTableId={triggersTableId} />
              {!canCreateTriggersTable && document.getElementById('duplicateTriggersTableItem') && isDropdownOpen && (
                <CustomPopover isOpen target="triggersTableOptionsDropdown" menuDimensions={menuDimensions}>
                  <CustomPopoverBody>
                    {t(
                      "header:You can't create more benchmarks. Please upgrade your plan in order to create more benchmarks.",
                    )}
                  </CustomPopoverBody>
                </CustomPopover>
              )}
            </Menu>
          </Dropdown>
        </Options>
        <InputNameModal
          triggersTable={triggersTable}
          title={t('Edit your triggers table')}
          subtitle={t('You can edit your triggers table here')}
          isOpen={isRenameModalOpen}
          toggle={this.toggleRenameModal}
        />
      </>
    );
  }
}

TriggersTableOptions.propTypes = {
  t: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  canCreateTriggersTable: PropTypes.bool,
  triggersTable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isDefault: PropTypes.bool.isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    settings: PropTypes.shape({
      analysisAxis: PropTypes.string.isRequired,
      calculationCriteria: PropTypes.string.isRequired,
      itemsToDisplay: PropTypes.shape({
        name: PropTypes.string.isRequired,
        key: PropTypes.string,
      }).isRequired,
      significanceThreshold: PropTypes.number.isRequired,
      range: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
      thematics: PropTypes.arrayOf(
        PropTypes.shape({
          thematic: PropTypes.string.isRequired,
          subThematic: PropTypes.string,
          subSubThematic: PropTypes.string,
        }),
      ).isRequired,
      criteriaKeys: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
            percentage: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
    customizations: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
  dispatchSetActiveTriggersTable: PropTypes.func.isRequired,
};

TriggersTableOptions.defaultProps = {
  canCreateTriggersTable: true,
};

const mapStateToProps = state => ({
  canCreateTriggersTable: get(state, ['auth', 'user', 'account', 'canCreateTriggersTable']) || true,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetActiveTriggersTable: newState => {
      dispatch(setActiveTriggersTable(newState));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('benchmark', 'header')(TriggersTableOptions));
