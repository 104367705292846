import { Col, Row } from 'reactstrap';
import moment from 'moment';
import Icon from '_Components/Icons/Icon';
import Select, { components } from 'react-select';
import React from 'react';
import { find } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  black,
  darkerPurple,
  grayShades,
  purple,
} from '../../../../styles/abstracts/colors';
import QPanelHeader from '../../../../_Components/Panels/QPanelHeader/QPanelHeader';
import { fontSizes } from '../../../../styles/abstracts/typography';
import QSwitcher from '../../../../_Components/Buttons/QSwitcher/QSwitcher';
import { LabelSettings, FormGroupSettings } from './SectionCard';

const StyledQPanelHeader = styled(QPanelHeader)`
  padding: 1rem;
  margin: 0 1rem;
`;
const TitleCol = styled(Col)`
  line-height: 3rem;
  padding-left: 0 !important;
  padding-bottom: 1rem;
`;

const EIndexFormGroup = styled(FormGroupSettings)`
  width: 160px;
`;

const AnalysisAxisFormGroup = styled(EIndexFormGroup)`
  width: 160px;
  margin-left: 3rem;
`;

const VerbatimLanguageLabelSettings = styled(LabelSettings)`
  width: 350px;
`;

const SelectInput = styled(Select)`
  color: ${darkerPurple} !important;
  width: 100%;
  & span {
    color: ${black} !important;
    font-weight: bold;
  }
  & div,
  & div:active {
    border-radius: 20px !important;
    background: ${grayShades.g300};
    border: none;
    font-size: ${fontSizes.default};
    color: ${purple} !important;
  }
`;
class PreferencesSection extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    verbatimsLocale: PropTypes.string.isRequired,
    eindexUnit: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      language: this.props.lang,
      menuOption: this.props.eindexUnit,
      verbatimsLocale:
        this.props.verbatimsLocale ||
        window.localStorage.getItem('verbatimsLocale'),
      analysisAxis: this.props.analysisAxis || 'emotion',
    };
  }

  selectLanguage = value => {
    this.setState({
      language: value,
    });
    moment.locale(value.value);
  };

  selectVerbatimsLanguage = value => {
    this.setState({
      verbatimsLocale: value,
    });
    value && window.localStorage.setItem('verbatimsLocale', value.value);
  };

  selectAnalysisAxis = value => {
    this.setState({
      analysisAxis: value,
    });
    value && window.localStorage.setItem('analysisAxis', value.value);
  };

  getSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: false,
        label: t('Celsius'),
      },
      {
        id: true,
        label: t('Fahrenheit'),
      },
    ];
  }

  getLanguages() {
    return [
      {
        value: 'de',
        label: 'Deutsch',
      },
      {
        value: 'en',
        label: 'English',
      },
      {
        value: 'es',
        label: 'Español',
      },
      {
        value: 'fr',
        label: 'Français',
      },
    ];
  }

  getVerbatimLanguages() {
    return [
      {
        value: 'fr',
        label: 'Français',
      },
      {
        value: 'en',
        label: 'English',
      },
      {
        value: 'default',
        label: 'Original',
      },
    ];
  }

  getAnalysisAxisSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'sentiment',
        label: t('Sentiment'),
      },
      {
        id: 'emotion',
        label: t('Emotion'),
      },
    ];
  }

  onMenuOptionSelected = menuOption => {
    this.setState({ menuOption });
  };

  onAnalysisAxisOptionSelected = analysisAxis => {
    this.setState({ analysisAxis });
  };

  componentDidMount() {
    this.props.callback({
      language: this.state.language,
      menuOption: this.props.eindexUnit,
      verbatimsLocale: this.state.verbatimsLocale || 'default',
      analysisAxis: this.state.analysisAxis || 'emotion',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lang !== prevProps.lang) {
      this.setState({
        language: this.props.lang,
      });
    }
    if (this.props.eindexUnit !== prevProps.eindexUnit) {
      this.setState({
        menuOption: this.props.eindexUnit,
      });
    }
    if (this.props.verbatimsLocale !== prevProps.verbatimsLocale) {
      this.setState({
        verbatimsLocale: this.props.verbatimsLocale,
      });
    }
    if (this.props.analysisAxis !== prevProps.analysisAxis) {
      this.setState({
        analysisAxis: this.props.analysisAxis,
      });
    }
    if (
      this.state.language !== prevState.language ||
      this.state.menuOption !== prevState.menuOption ||
      this.state.verbatimsLocale !== prevState.verbatimsLocale ||
      this.state.analysisAxis !== prevState.analysisAxis
    ) {
      this.props.callback({
        language: this.state.language,
        menuOption: this.state.menuOption,
        verbatimsLocale: this.state.verbatimsLocale,
        analysisAxis: this.state.analysisAxis,
      });
    }
  }

  render() {
    const languages = this.getLanguages();
    const currentLanguage = find(
      languages,
      language =>
        language.value ===
        (this.state.language
          ? this.state.language.value || this.state.language
          : window.localStorage.getItem('lang')),
    );

    const verbatimsLanguages = this.getVerbatimLanguages();
    const currentVerbatimsLanguage = find(
      verbatimsLanguages,
      verbatimsLanguage =>
        verbatimsLanguage.value ===
        (this.state.verbatimsLocale
          ? this.state.verbatimsLocale.value || this.state.verbatimsLocale
          : window.localStorage.getItem('verbatimsLocale')),
    );

    const Input = ({ autoComplete, ...props }) => (
      <components.Input {...props} autoComplete="off" />
    );
    console.log("currentVerbatimsLanguage:", currentVerbatimsLanguage);

    return (
      <StyledQPanelHeader type="menu">
        <Row>
          <TitleCol>{this.props.t('Preferences')}</TitleCol>
        </Row>
        <Row>
          <FormGroupSettings>
            <form autoComplete="off" action="">
              <LabelSettings>
                {this.props.t('Interface language')}
              </LabelSettings>
              <SelectInput
                name="categorySelect"
                value={currentLanguage}
                searchable={false}
                clearable={false}
                onChange={this.selectLanguage}
                options={languages}
                arrowRenderer={() => (
                  <Icon icon="INTERFACE_CHEVRON_DOWN" color="secondary" />
                )}
                components={{ Input }}
              />
            </form>
          </FormGroupSettings>
          <FormGroupSettings className="ml-4">
            <form autoComplete="off" action="">
              <VerbatimLanguageLabelSettings>
                {this.props.t('Verbatim language')}
              </VerbatimLanguageLabelSettings>
              <SelectInput
                name="categorySelect"
                value={currentVerbatimsLanguage}
                searchable={false}
                clearable={false}
                onChange={this.selectVerbatimsLanguage}
                options={verbatimsLanguages}
                arrowRenderer={() => (
                  <Icon icon="INTERFACE_CHEVRON_DOWN" color="secondary" />
                )}
                components={{ Input }}
              />
            </form>
          </FormGroupSettings>
        </Row>
        <Row>
          <EIndexFormGroup>
            <LabelSettings>{this.props.t('E-Index unit')}</LabelSettings>
            <QSwitcher
              selected={this.state.menuOption}
              options={this.getSwitcherOptions()}
              onOptionSelected={this.onMenuOptionSelected}
              padding="0.5em 0.5em"
              borderradius="2em"
            />
          </EIndexFormGroup>
          <AnalysisAxisFormGroup>
            <LabelSettings>{this.props.t('button:Analysis axis')}</LabelSettings>
            <QSwitcher
              selected={this.state.analysisAxis}
              options={this.getAnalysisAxisSwitcherOptions()}
              onOptionSelected={this.onAnalysisAxisOptionSelected}
              padding="0.5em 0.5em"
              borderradius="2em"
            />
          </AnalysisAxisFormGroup>
        </Row>
      </StyledQPanelHeader>
    );
  }
}
export default withTranslation('card')(PreferencesSection);
