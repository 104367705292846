/* eslint-disable no-console  */
/* eslint-disable react/sort-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import QcardPanel from '_Components/QcardPanel/QcardPanel';
import styled from 'styled-components';
import { HintsContent } from '_Components/QcardPanel/QcardHeader/QcardHints/Hint';
import TriggersColumn from './TriggersColumn';

const StyledContainer = styled(Container)`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  position: relative;
  top: 1rem;
`;

const TriggersColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  gap: 1rem;
`;
class Triggers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idpage: 'triggers',
    };
  }

  componentDidMount() {
    const { dispatchUpdateRoute } = this.props;
    const { idpage } = this.state;
    getRoute(dispatchUpdateRoute, idpage);
  }

  render() {
    const { activeTriggersTable } = this.props;
    const { data, title, description, settings } = activeTriggersTable || {};

    // console.log('activeTriggersTable:', activeTriggersTable);

    return (
      <div>
        <StyledContainer fluid className="p-0">
          {activeTriggersTable && (
            <QcardPanel
              title={title}
              displayHints
              hints={[
                HintsContent.HINT_VARIANCE_ANALYSIS_INTRODUCTION,
                HintsContent.HINT_VARIANCE_ANALYSIS_TONALITY_VOLUME_EFFECTS,
                HintsContent.HINT_VARIANCE_ANALYSIS_MIX_EFFECT,
              ]}
              subtitleAdditionalContent={description}
              // numberOfMentions={150}
              triggersSection
            >
              <TriggersColumnsContainer>
                {data?.length > 0 && data.map(triggersColumn => <TriggersColumn columnData={triggersColumn} />)}
              </TriggersColumnsContainer>
            </QcardPanel>
          )}
        </StyledContainer>
      </div>
    );
  }
}

Triggers.propTypes = {
  dispatchUpdateRoute: PropTypes.func.isRequired,
  activeTriggersTable: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isDefault: PropTypes.bool.isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    settings: PropTypes.shape({
      analysisAxis: PropTypes.string.isRequired,
      calculationCriteria: PropTypes.string.isRequired,
      itemsToDisplay: PropTypes.shape({
        name: PropTypes.string.isRequired,
        key: PropTypes.string,
      }).isRequired,
      significanceThreshold: PropTypes.number.isRequired,
      range: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
      }).isRequired,
      thematics: PropTypes.arrayOf(
        PropTypes.shape({
          thematic: PropTypes.string.isRequired,
          subThematic: PropTypes.string,
          subSubThematic: PropTypes.string,
        }),
      ).isRequired,
      criteriaKeys: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
      ).isRequired,
    }).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
            percentage: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
    customizations: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        originalColor: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        originalName: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            originalName: PropTypes.string.isRequired,
          }),
        ).isRequired,
        value: PropTypes.number.isRequired,
        deleted: PropTypes.bool.isRequired,
        masked: PropTypes.bool.isRequired,
      }),
    ),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    eindexUnitFahrenheit: false,
    activeTriggersTable: get(state, ['triggers', 'activeTriggersTable']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Triggers);
