import { addOriginalColorsInData } from "_Resources/Triggers/Services/helpers";

const idleState = {
  settings: {
    analysisAxis: 'emotion',
    itemsToDisplay: 'thematics',
    calculationCriteria: 'percentage',
    volumeThreshold: 5,
    range: {
      startDate: '',
      endDate: '',
    },
    thematics: [],
    criteriaKeys: [],
  },
};

export const setActiveTriggersTable = (state, { activeTriggersTable }) => {
  if (activeTriggersTable) {
    // console.log("activeTriggersTable:", activeTriggersTable);

    const { settings, id, title, description, isDefault, data, customizations } = activeTriggersTable;

    const dataWithColors = addOriginalColorsInData(data);

    return {
      ...state,
      activeTriggersTable: {
        ...idleState,
        id,
        title,
        description,
        isDefault,
        settings,
        data: dataWithColors,
        customizations,
      },
    };
  }
  return {
    ...state,
    activeTriggersTable: null,
  };
};
