import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Mutation } from 'react-apollo';
import Icon from '_Components/Icons/Icon';
import { red } from '../../../styles/abstracts/colors';
import { ImageSize, spacing } from '../../../styles/abstracts/variables';
import { fontSizes } from '../../../styles/abstracts/typography';
import queryGetEmotionalTriggersTableList from './graphql/getEmotionalTriggersTableList';
import mutationDeleteEmotionalTriggerTable from './graphql/deleteEmotionalTriggerTable';

const OptionsItem = styled(DropdownItem)`
  font-weight: bold !important;
  color: ${props => (props.delete ? red : `#2e096b`)} !important;
  padding: ${spacing.medium} !important;
  font-size: ${fontSizes.smaller};
`;

class MutationDeleteEmotionalTriggerTable extends React.Component {
  render() {
    const { t, triggersTableId, canCreateTriggersTable, currentProject } = this.props;
    const { id: projectId } = currentProject;
    return (
      <Mutation
        mutation={mutationDeleteEmotionalTriggerTable}
        update={cache => {
          const triggersTablesList = cache.readQuery({
            query: queryGetEmotionalTriggersTableList,
            variables: { projectId },
          }).getEmotionalTriggersTableList.emotionalTriggersTables;
          cache.writeQuery({
            query: queryGetEmotionalTriggersTableList,
            variables: { projectId },
            data: {
              getEmotionalTriggersTableList: {
                emotionalTriggersTables: triggersTablesList.filter(
                  triggersTable => triggersTable.id !== triggersTableId,
                ),
              },
            },
          });
        }}
      >
        {deleteEmotionalTriggerTable => (
          <OptionsItem
            id="archiveTriggersTableItem"
            disabled={!canCreateTriggersTable}
            delete
            onClick={() => {
              deleteEmotionalTriggerTable({
                variables: {
                  projectId,
                  id: triggersTableId,
                },
              });
            }}
          >
            <Icon icon="TRASH" color={red} size={ImageSize.small} />
            &nbsp; {t('Delete')}
          </OptionsItem>
        )}
      </Mutation>
    );
  }
}

MutationDeleteEmotionalTriggerTable.propTypes = {
  t: PropTypes.func.isRequired,
  currentProject: PropTypes.number.isRequired,
  triggersTableId: PropTypes.number.isRequired,
  canCreateTriggersTable: PropTypes.bool,
};

MutationDeleteEmotionalTriggerTable.defaultProps = {
  canCreateTriggersTable: true,
};

const mapStateToProps = state => ({
  currentProject: state.projects.currentProject,
  canCreateTriggersTable: state.auth.user.account.canCreateTriggersTable || true,
});

export default connect(mapStateToProps)(withTranslation('benchmark')(MutationDeleteEmotionalTriggerTable));
