import { combineReducers } from 'redux';
import routes from '_Resources/Header/Reducers/routes';
import auth from '_Resources/Auth/Reducers/auth';
import alerting from '_Resources/Alerting/Reducers/alerting';
import insight from '_Resources/Insight/Reducers/insight';
import cluster from '_Resources/Cluster/Reducers/cluster.ts';
import projects from '_Resources/Header/Reducers/projects';
import periods from '_Resources/Header/Reducers/periods';
import projectConfiguration from '_Resources/Header/Reducers/configuration';
import reporting from '_Resources/Reporting/Reducers/reporting';
import filters from '_Resources/Header/Reducers/filters';
import mentions from '_Resources/Header/Reducers/mentions';
import specificFilters from '_Resources/SpecificFiltering/Reducers/specificFilters';
import cards from '_Resources/Cards/Reducers/cards';
import benchmark from '_Resources/Benchmark/Reducers/benchmark';
import tourSteps from '_Resources/Header/Reducers/tour';
import sentence from '_Resources/Sentence/Reducers/sentence';
import verbatim from '_Resources/Verbatim/Reducers/verbatim';
import triggers from '_Resources/Triggers/Reducers/triggers';

export default combineReducers({
  alerting,
  auth,
  benchmark,
  cards,
  cluster,
  filters,
  insight,
  mentions,
  periods,
  projectConfiguration,
  projects,
  reporting,
  routes,
  sentence,
  specificFilters,
  tourSteps,
  triggers,
  // setting,
  verbatim,
});
