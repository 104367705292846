import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import QSwitcher from '_Components/Buttons/QSwitcher/QSwitcher';
import { grayShades } from 'styles/abstracts/colors';
import DatePickerContainer from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/DatePickerContainer';
import CriteriaKeysFilter from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/CriteriaKeysFilter';
import ThematicsFilter from 'Routes/Insights/Triggers/_Components/Modals/ModalPages/_Components/Inputs/ThematicsFilter';
import { setTriggersRangeFilter } from '_Resources/Triggers/Actions/Filters/setTriggersRangeFilter';
import { isEqual, get } from 'lodash';
import { connect } from 'react-redux';
import { FILTER_TYPES } from 'Routes/Insights/Triggers/_Components/configs/filtersConfig';
import { addTriggersFilters } from '_Resources/Triggers/Actions/Filters/addTriggersFilters';
import { removeTriggersFilters } from '_Resources/Triggers/Actions/Filters/removeTriggersFilters';
import { isValidFullDateRange } from 'Routes/Insights/Triggers/_Utils/checkDateRange';

const SwitcherContainer = styled(Col)`
  padding: 1rem;
  border-bottom: 1px solid ${grayShades.g400};
`;

const CustomCol = styled(Col)`
  margin: 1em;
`;

class FiltersSwitcher extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    /* eslint-disable react/forbid-prop-types */
    thematicsFilters: PropTypes.any.isRequired,
    normalFilters: PropTypes.any.isRequired,
    projectPeriods: PropTypes.any.isRequired,
    /* eslint-enable react/forbid-prop-types */
    context: PropTypes.string.isRequired,
    onRemovedNormalFilter: PropTypes.func.isRequired,
    onSelectedNormalFilter: PropTypes.func.isRequired,
    dispatchSetTriggersSetRangeFilter: PropTypes.func.isRequired,
    onRemovedThematicFilter: PropTypes.func.isRequired,
    onSelectedThematicFilter: PropTypes.func.isRequired,
    triggersDateRangeSettings: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  onPeriodUpdate = ({ startDate, endDate }) => {
    if (!isEqual({ startDate, endDate }, this.props.currentPeriod)) {
      this.props.dispatcher(this.props.context, {
        startDate,
        endDate,
      });
      this.setState({ hadDateBeenModified: true });
    }
  };

  getRangeDate() {
    const { currentPeriod, triggersDateRangeSettings } = this.props;
    const specificDateRangeSettings = triggersDateRangeSettings || {};
    if (!specificDateRangeSettings.startDate || !specificDateRangeSettings.endDate) {
      this.props.dispatchSetTriggersSetRangeFilter(currentPeriod);
      return currentPeriod;
    }
    return { ...specificDateRangeSettings };
  }

  dispatchTriggersRangeFilters(range) {
    const globalProjectRange = this.props.projectPeriods.byAll.period;
    const finalValidFullDateRange = isValidFullDateRange(range, globalProjectRange);
    this.props.dispatchSetTriggersSetRangeFilter(finalValidFullDateRange);
  }

  content = {
    'date-range': () => (
      <>
        <DatePickerContainer
          dispatcher={(type, range) => this.dispatchTriggersRangeFilters(range)}
          projectPeriods={this.props.projectPeriods}
          dateRange={this.getRangeDate()}
          title="Period"
        />
      </>
    ),
    thematics: () => (
      <ThematicsFilter
        context={this.props.context}
        onRemoved={this.props.onRemovedThematicFilter}
        onSelected={this.props.onSelectedThematicFilter}
        thematics={this.props.thematicsFilters}
        initialValues={get(this.props, ['currentSettingsFilters', 'thematics']) || null}
      />
    ),
    filters: () => (
      <CriteriaKeysFilter
        context={this.props.context}
        onRemoved={this.props.onRemovedNormalFilter}
        onSelected={this.props.onSelectedNormalFilter}
        normalFilters={this.props.normalFilters}
        initialValues={get(this.props, ['currentSettingsFilters', 'criteriaKeys']) || null}
      />
    ),
  };

  state = {
    menuOption: 'date-range',
  };

  getSwitcherOptions() {
    const { t } = this.props;
    return [
      {
        id: 'date-range',
        label: t('Date range'),
      },
      {
        id: 'thematics',
        label: t('Thematics'),
      },
      {
        id: 'filters',
        label: t('Filters'),
      },
    ];
  }

  onMenuOptionSelected = menuOption => {
    this.setState({ menuOption });
  };

  render() {
    const { menuOption } = this.state;
    const content = this.content[menuOption];
    // console.log("this.props : ", this.props);
    return (
      <>
        <Row>
          <SwitcherContainer>
            <QSwitcher
              selected={menuOption}
              options={this.getSwitcherOptions()}
              onOptionSelected={this.onMenuOptionSelected}
            />
          </SwitcherContainer>
        </Row>
        <Row>
          <CustomCol>{content()}</CustomCol>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ periods: { projectPeriods, currentPeriod }, triggers, filters: { projectFilters } }) => ({
  projectPeriods,
  currentPeriod,
  activeTriggersTable: get(triggers, ['activeTriggersTable']),
  triggersDateRangeSettings: get(triggers, ['temporaryTriggers', 'settings', 'range']),
  normalFilters: get(projectFilters, ['criteriaKeys', 'normal']),
  thematicsFilters: get(projectFilters, ['thematics']),
});

const mapDispatchToProps = (dispatch, { context }) => ({
  dispatchSetTriggersSetRangeFilter: range => {
    dispatch(setTriggersRangeFilter(range));
  },
  onSelectedNormalFilter: filterValues => dispatch(addTriggersFilters(context, FILTER_TYPES.CRITERIA_KEYS, filterValues)),
  onRemovedNormalFilter: filterValues => dispatch(removeTriggersFilters(context, FILTER_TYPES.CRITERIA_KEYS, [filterValues, ''])),
  onSelectedThematicFilter: filterValues => dispatch(addTriggersFilters(context, FILTER_TYPES.THEMATICS, filterValues)),
  onRemovedThematicFilter: (...args) => dispatch(removeTriggersFilters(context, FILTER_TYPES.THEMATICS, args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('reporting')(FiltersSwitcher));
