import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import Qcard from '_Components/Qcard/Qcard';
import QcardHeader from '_Components/QcardPanel/QcardHeader/QcardHeader';
import { withTranslation } from 'react-i18next';

const Header = styled(Row)`
  display: block;
  flex-shrink: 0;
  width: ${props => (props.landingSection ? '100%' : '')};
`;

const SubHeader = styled(Row)`
  flex-shrink: 0;
`;

const Content = styled(Row)`
  flex-grow: 1;
  margin-left: 0 !important;
  margin-right: 0 !important;
  overflow-y: ${({ scroll }) => scroll && 'auto !important'};
  width: 100%;
`;

const CustomCol = styled(Col)`
  height: 100%;
  overflow: ${({ hiddenOverflow }) => (hiddenOverflow ? 'hidden' : 'visible')};
  ${({ triggersSection }) => (triggersSection ? 'display: flex;' : null)};
  ${({ triggersSection }) => (triggersSection ? 'overflow: auto;' : null)};
`;

class QcardPanel extends React.Component {
  render() {
    const {
      displayCardTitle,
      displayCardSubTitle,
      displayCardHeader,
      displayBackgroundCard,
      hints,
      dropDownOptions,
      specificDropDownOptions,
      displayHints,
      title,
      titleSecondPart,
      subtitleAdditionalContent,
      numberOfMentions,
      buttonRight,
      isCardOpened,
      dataByFiltersCard,
      referenceMentions,
      referencePeriod,
      displayDateSubtitle,
      landingSection,
      triggersSection,
      additionalIcon,
      additionalAction,
      exportClustersData,
      footer,
      className,
      style,
      closeCard,
      subHeader,
      scroll,
      sideBar,
      CustomCSSBodyCol,
      children,
    } = this.props;
    const propsHeader = {
      displayCardTitle,
      displayCardSubTitle,
      hints,
      dropDownOptions,
      specificDropDownOptions,
      displayHints,
      title,
      titleSecondPart,
      subtitleAdditionalContent,
      numberOfMentions,
      isCardOpened,
      buttonRight,
      dataByFiltersCard,
      referenceMentions,
      referencePeriod,
      displayDateSubtitle,
      landingSection,
      triggersSection,
      additionalIcon,
      additionalAction,
      exportClustersData,
    };

    const hasHiddenOverflow = () => {
      const cardsWithVisibleOverflow = [
        'Word frequency',
        'Category ranking',
        'Mention breakdown by category',
        'Your alerts',
        'Waterfall view by category',
      ];
      return !cardsWithVisibleOverflow.includes(title);
    };

    return (
      <Qcard
        className={`${className} qcard--panel`}
        displayBackgroundCard={displayBackgroundCard}
        style={style}
        id={title}
        triggersSection={triggersSection}
      >
        {displayCardHeader && (
          <Header className="qcard--panel header" landingSection={landingSection}>
            <Col>
              <QcardHeader {...propsHeader} closeCard={() => closeCard} />
            </Col>
          </Header>
        )}
        <SubHeader className="qcard--panel subHeader">
          <Col>{subHeader}</Col>
        </SubHeader>
        <Content scroll={scroll} className="qcard--panel content">
          {sideBar}
          <CustomCol
            className={`${CustomCSSBodyCol || ''} qcard--panel customCol`}
            hiddenOverflow={hasHiddenOverflow()}
            triggersSection={triggersSection}
          >
            {children}
          </CustomCol>
        </Content>
        {footer}
      </Qcard>
    );
  }
}

QcardPanel.propTypes = {
  displayCardHeader: PropTypes.bool,
  displayBackgroundCard: PropTypes.bool,
  title: PropTypes.string,
  titleSecondPart: PropTypes.string,
  numberOfMentions: PropTypes.number,
  displayCardTitle: PropTypes.bool,
  displayCardSubTitle: PropTypes.bool,
  hints: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  data: PropTypes.any,
  children: PropTypes.any,
  hideFilterDates: PropTypes.bool,
  displayHints: PropTypes.bool,
  dropDownOptions: PropTypes.arrayOf(PropTypes.string),
  specificDropDownOptions: PropTypes.arrayOf(PropTypes.string),
  footer: PropTypes.any,
  triggersSection: PropTypes.bool,
  subtitleAdditionalContent: PropTypes.string,
};

QcardPanel.defaultProps = {
  displayCardHeader: true,
  displayBackgroundCard: true,
  displayCardTitle: true,
  displayCardSubTitle: true,
  titleSecondPart: null,
  title: null,
  numberOfMentions: null,
  hints: [],
  data: null,
  children: null,
  hideFilterDates: false,
  displayHints: true,
  dropDownOptions: null,
  specificDropDownOptions: null,
  footer: null,
  triggersSection: false,
  subtitleAdditionalContent: null,
};

export default withTranslation('card')(QcardPanel);
