/* eslint-disable no-console  */
/* eslint-disable react/sort-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { updateRoute } from '_Resources/Header/Actions/updateRoute';
import { getRoute } from '_Utils/routes/routeUtils';
import InsightWallGrid from './_Containers/InsightWallGrid/InsightWallGrid';

class InsightsWall extends PureComponent {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      idpage: 'insights',
    };
  }

  componentDidMount() {
    getRoute(this.props.updateRoute, this.state.idpage);
  }

  render() {
    return (
      <div>
        <Container fluid className="p-0">
          <InsightWallGrid
            onInsightCreated={this.onInsightCreated}
            fahrenheit={get(this.props, ['eindexUnitFahrenheit']) || false}
          />
        </Container>
      </div>
    );
  }
}

InsightsWall.propTypes = {
  updateRoute: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    eindexUnitFahrenheit: false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRoute: newState => {
      dispatch(updateRoute(newState));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InsightsWall);
