import React from 'react';
import Layout from '_Layouts/Layout';
import QLoginRedirect from '_Components/Auth/QLoginRedirect';
// import InsightWall from './InsightsWall';
import Triggers from './Triggers';
// import BenchmarkSubHeader from '../../Benchmark/BenchmarkBoard/_Components/BenchmarkSubHeader/BenchmarkSubHeader';
import TriggersSubHeader from './_Components/TriggersSubHeader/TriggersSubHeader';

const title = 'Triggers';
// const body = <InsightWall />;
const subHeader = <TriggersSubHeader />;
const body = <Triggers />;

const action = props => {
  return (
    <QLoginRedirect>
      <Layout body={body} allowCollapse title={title} {...props} subHeader={subHeader} />
    </QLoginRedirect>
  );
};

export default action;
