import { GET_PROJECT_CRITERIA_KEYS_AND_THEMATICS } from '_Resources/Header/Actions/getProjectCriteriaKeysAndThematics';
import { SET_GLOBAL_QUERY_FILTER_KEYS } from '_Resources/Header/Actions/setGlobalQueryFilterKeys';
import { SET_SELECTED_FILTERS } from '../Actions/setSelectedFilters';
import { SWITCH_FILTER_SELECTED } from '../Actions/setSelectedSwitchFilter';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_CRITERIA_KEYS_AND_THEMATICS: {
      return {
        ...state,
        projectFilters: action.data.results,
      };
    }
    case SET_GLOBAL_QUERY_FILTER_KEYS: {
      action.data.projectId && window.localStorage.setItem('filters', JSON.stringify(action.data));
      return {
        ...state,
        filterKeys: action.data,
      };
    }
    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: action.data,
      };
    }
    case SWITCH_FILTER_SELECTED: {
      return {
        ...state,
        selectedClusters: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
