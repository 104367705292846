import { SET_ACTIVE_TRIGGERS_TABLE } from '../Actions/setActiveTriggersTable';
import { setActiveTriggersTable } from './helpers/activeTriggersTable';
import { addTriggersFilters, removeTriggersFilters } from './helpers/criteriaKeysFilters';
import { SET_TRIGGERS_RANGE_FILTER } from '../Actions/Filters/setTriggersRangeFilter';
import { ADD_TRIGGERS_FILTERS } from '../Actions/Filters/addTriggersFilters';
import { SET_TRIGGERS_TABLE_NAME } from '../Actions/Filters/setTriggersTableName';
import { SET_TRIGGERS_TABLE_ANALYSIS_AXIS } from '../Actions/Filters/setTriggersTableAnalysisAxis';
import { SET_TRIGGERS_TABLE_ITEMS } from '../Actions/Filters/setTriggersTableItems';
import { SET_TRIGGERS_TABLE_CALCULATION_CRITERIA } from '../Actions/Filters/setTriggersTableCalculationCriteria';
import { SET_TRIGGERS_TABLE_THRESHOLD } from '../Actions/Filters/setTriggersTableThreshold';
import { SET_TRIGGERS_TABLE_DESCRIPTION } from '../Actions/Filters/setTriggersTableDescription';
import { REMOVE_TRIGGERS_FILTERS } from '../Actions/Filters/removeTriggersFilters';
import { SET_PROJECT_TRIGGERS_TABLES } from '../Actions/setProjectTriggersTables';

const initialState = {
  activeTriggersTable: {
    id: '',
    title: '',
    description: '',
    isDefault: false,
    settings: {
      analysisAxis: 'emotion',
      itemsToDisplay: 'thematics',
      calculationCriteria: 'percentage',
      volumeThreshold: 5,
      range: {
        startDate: '',
        endDate: '',
      },
      thematics: [],
      criteriaKeys: [],
    },
  },
  projectTriggersTables: [],
  temporaryTriggers: {
    title: '',
    description: '',
    settings: {
      analysisAxis: 'emotion',
      itemsToDisplay: 'thematics',
      calculationCriteria: 'percentage',
      volumeThreshold: 5,
      range: {
        startDate: '',
        endDate: '',
      },
      thematics: [],
      criteriaKeys: [],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TRIGGERS_TABLE: {
      return setActiveTriggersTable(state, action);
    }
    case SET_PROJECT_TRIGGERS_TABLES: {
      return {
        ...state,
        projectTriggersTables: action.data,
      };
    }
    case SET_TRIGGERS_RANGE_FILTER: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            range: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_NAME: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          title: action.data,
        },
      };
    }
    case SET_TRIGGERS_TABLE_DESCRIPTION: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          description: action.data,
        },
      };
    }
    case SET_TRIGGERS_TABLE_ANALYSIS_AXIS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            analysisAxis: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_ITEMS: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            itemsToDisplay: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_CALCULATION_CRITERIA: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            calculationCriteria: action.data,
          },
        },
      };
    }
    case SET_TRIGGERS_TABLE_THRESHOLD: {
      return {
        ...state,
        temporaryTriggers: {
          ...state.temporaryTriggers,
          settings: {
            ...state.temporaryTriggers.settings,
            volumeThreshold: action.data,
          },
        },
      };
    }
    case ADD_TRIGGERS_FILTERS: {
      return addTriggersFilters(state, action);
      // return {
      //   ...state,
      //   thematics: ['cool'],
      // };
    }
    case REMOVE_TRIGGERS_FILTERS: {
      return removeTriggersFilters(state, action);
    }
    default: {
      return state;
    }
  }
};
